var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "all-container" },
    [
      _vm.tabList && _vm.tabList.length > 0
        ? _c(
            "el-tabs",
            {
              staticClass: "left",
              attrs: { "tab-position": "left" },
              on: { "tab-click": _vm.tabClick },
              model: {
                value: _vm.activeTab,
                callback: function ($$v) {
                  _vm.activeTab = $$v
                },
                expression: "activeTab",
              },
            },
            _vm._l(_vm.tabList, function (item, index) {
              return _c("el-tab-pane", {
                key: index,
                attrs: { label: item.activityName, name: item.id },
              })
            }),
            1
          )
        : _vm._e(),
      _vm.costActivityObj && _vm.costActivityObj.activityCode
        ? _c(
            "div",
            { staticClass: "body" },
            [
              _c("Title", [_vm._v("基本信息")]),
              _c(
                "el-form",
                { attrs: { "label-width": "100px" } },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "活动编码" } },
                            [
                              _c("TextEllipsis", {
                                attrs: {
                                  text: _vm.costActivityObj.activityCode,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "活动名称" } },
                            [
                              _c("TextEllipsis", {
                                attrs: {
                                  text: _vm.costActivityObj.activityName,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "活动类型" } },
                            [
                              _c("TextEllipsis", {
                                attrs: { text: "费用活动" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "活动执行时间" } },
                            [
                              _vm.costActivityObj.activityStartTime &&
                              _vm.costActivityObj.activityEndTime
                                ? [
                                    _c("TextEllipsis", {
                                      attrs: {
                                        text:
                                          _vm.costActivityObj
                                            .activityStartTime +
                                          " 至 " +
                                          _vm.costActivityObj.activityEndTime,
                                      },
                                    }),
                                  ]
                                : _vm._e(),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("Title", [_vm._v("活动信息")]),
              _c(
                "el-form",
                { attrs: { "label-width": "100px" } },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "预估销售额" } },
                            [
                              _c("TextEllipsis", {
                                attrs: {
                                  text: _vm.costActivityObj.salesVolume,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "费用申请金额" } },
                            [
                              _c("TextEllipsis", {
                                attrs: {
                                  text: _vm.costActivityObj.applyAmount,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "活动需求" } },
                            [
                              _c("TextEllipsis", {
                                attrs: {
                                  text: _vm.costActivityObj.activityRequireDesc,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "活动描述" } },
                            [
                              _c("TextEllipsis", {
                                attrs: {
                                  text: _vm.costActivityObj.activityDesc,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("Title", [_vm._v("执行结果")]),
              _vm.costActivityObj.activityRequireReqVoList &&
              _vm.costActivityObj.activityRequireReqVoList.length > 0
                ? _c(
                    "div",
                    [
                      _c(
                        "el-form",
                        { attrs: { "label-width": "100px" } },
                        _vm._l(
                          _vm.costActivityObj.activityRequireReqVoList,
                          function (item, index) {
                            return _c(
                              "el-form-item",
                              {
                                key: index,
                                attrs: { label: item.activityRequireName },
                              },
                              [
                                item.pictureList && item.pictureList.length > 0
                                  ? _c(
                                      "div",
                                      _vm._l(
                                        item.pictureList,
                                        function (pItem, pIndex) {
                                          return _c("el-image", {
                                            key: pIndex,
                                            staticClass: "image",
                                            attrs: {
                                              src:
                                                pItem.urlPathPrefix +
                                                pItem.urlPath,
                                              "preview-src-list": [
                                                pItem.urlPathPrefix +
                                                  pItem.urlPath,
                                              ],
                                            },
                                          })
                                        }
                                      ),
                                      1
                                    )
                                  : _c("EmptyImage", { staticClass: "mb-10" }),
                              ],
                              1
                            )
                          }
                        ),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "div",
                { staticClass: "mt-20" },
                [_c("ExtendForm", { attrs: { value: _vm.costActivityObj } })],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }