var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "all-container" },
    [
      _vm.tabList && _vm.tabList.length > 0
        ? _c(
            "el-tabs",
            {
              staticClass: "left",
              attrs: { "tab-position": "left" },
              on: { "tab-click": _vm.tabClick },
              model: {
                value: _vm.activeTab,
                callback: function ($$v) {
                  _vm.activeTab = $$v
                },
                expression: "activeTab",
              },
            },
            _vm._l(_vm.tabList, function (item, index) {
              return _c("el-tab-pane", {
                key: index,
                attrs: { label: item.activityName, name: item.activityCode },
              })
            }),
            1
          )
        : _vm._e(),
      _vm.activityObj && _vm.activityObj.activityCode
        ? _c(
            "div",
            { staticClass: "body" },
            [
              _c("Title", [_vm._v("基本信息")]),
              _c(
                "el-form",
                { attrs: { "label-width": "100px" } },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "活动编码" } },
                            [
                              _c("TextEllipsis", {
                                attrs: { text: _vm.activityObj.activityCode },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "活动名称" } },
                            [
                              _c("TextEllipsis", {
                                attrs: { text: _vm.activityObj.activityName },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "活动类型" } },
                            [
                              _c("TextEllipsis", {
                                attrs: {
                                  text: _vm.activityObj.activityTypeDesc,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "陈列类型" } },
                            [
                              _c("TextEllipsis", {
                                attrs: {
                                  text: _vm.activityObj.displayTypeDesc,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "兑付方式" } },
                            [
                              _c("TextEllipsis", {
                                attrs: {
                                  text: _vm.activityObj.paymentMethodDesc,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "活动状态" } },
                            [
                              _c("TextEllipsis", {
                                attrs: {
                                  text: _vm.activityObj.activityTypeDesc,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "活动开始时间" } },
                            [
                              _c("TextEllipsis", {
                                attrs: {
                                  text: _vm.activityObj.activityStartTime,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "活动结束时间" } },
                            [
                              _c("TextEllipsis", {
                                attrs: {
                                  text: _vm.activityObj.activityEndTime,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("Title", [_vm._v("商品数据")]),
              _vm.activityObj.sfaActSchemeRespVo &&
              _vm.activityObj.sfaActSchemeRespVo.actSchemeSetmealList.length > 0
                ? _c(
                    "div",
                    { staticClass: "product-container" },
                    [
                      _vm._l(
                        _vm.activityObj.sfaActSchemeRespVo.actSchemeSetmealList,
                        function (item, index) {
                          return [
                            _c(
                              "div",
                              { key: index },
                              [
                                _c("div", { staticClass: "product-title" }, [
                                  _vm._v("套餐" + _vm._s(index + 1)),
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "product-title-item" },
                                  [_vm._v("本品")]
                                ),
                                _c(
                                  "vxe-table",
                                  {
                                    staticClass: "product-table",
                                    attrs: { data: item.bpSchemeProductList },
                                  },
                                  [
                                    _c("vxe-table-column", {
                                      attrs: { title: "序号", width: "100px" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (ref) {
                                              var rowIndex = ref.rowIndex
                                              return [
                                                _vm._v(_vm._s(rowIndex + 1)),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    }),
                                    _c("vxe-table-column", {
                                      attrs: {
                                        title: "产品编码",
                                        field: "productCode",
                                        "min-width": "100px",
                                        "show-overflow": "",
                                      },
                                    }),
                                    _c("vxe-table-column", {
                                      attrs: {
                                        title: "产品名称",
                                        field: "productName",
                                        "min-width": "100px",
                                        "show-overflow": "",
                                      },
                                    }),
                                    _c("vxe-table-column", {
                                      attrs: {
                                        title: "单价",
                                        field: "price",
                                        "min-width": "100px",
                                        "show-overflow": "",
                                      },
                                    }),
                                    _c("vxe-table-column", {
                                      attrs: {
                                        title: "单位",
                                        field: "unitName",
                                        "min-width": "100px",
                                        "show-overflow": "",
                                      },
                                    }),
                                    _c("vxe-table-column", {
                                      attrs: {
                                        title: "数量",
                                        field: "quantity",
                                        "min-width": "100px",
                                        "show-overflow": "",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "product-title-item" },
                                  [_vm._v("赠品")]
                                ),
                                _c(
                                  "vxe-table",
                                  {
                                    staticClass: "product-table",
                                    attrs: { data: item.zpSchemeProductList },
                                  },
                                  [
                                    _c("vxe-table-column", {
                                      attrs: { title: "序号", width: "100px" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (ref) {
                                              var rowIndex = ref.rowIndex
                                              return [
                                                _vm._v(_vm._s(rowIndex + 1)),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    }),
                                    _c("vxe-table-column", {
                                      attrs: {
                                        title: "产品编码",
                                        field: "productCode",
                                        "min-width": "100px",
                                        "show-overflow": "",
                                      },
                                    }),
                                    _c("vxe-table-column", {
                                      attrs: {
                                        title: "产品名称",
                                        field: "productName",
                                        "min-width": "100px",
                                        "show-overflow": "",
                                      },
                                    }),
                                    _c("vxe-table-column", {
                                      attrs: {
                                        title: "单价",
                                        field: "price",
                                        "min-width": "100px",
                                        "show-overflow": "",
                                      },
                                    }),
                                    _c("vxe-table-column", {
                                      attrs: {
                                        title: "单位",
                                        field: "unitName",
                                        "min-width": "100px",
                                        "show-overflow": "",
                                      },
                                    }),
                                    _c("vxe-table-column", {
                                      attrs: {
                                        title: "数量",
                                        field: "quantity",
                                        "min-width": "100px",
                                        "show-overflow": "",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        }
                      ),
                    ],
                    2
                  )
                : _vm._e(),
              _vm._l(_vm.pictureList, function (item, index) {
                return _c(
                  "div",
                  { key: index },
                  [
                    _c("Title", [
                      _vm._v(_vm._s(item.displayTypeDesc) + "陈列照"),
                    ]),
                    item.pictureRespVos && item.pictureRespVos.length > 0
                      ? _c(
                          "div",
                          _vm._l(item.pictureRespVos, function (pItem, pIndex) {
                            return _c("el-image", {
                              key: pIndex,
                              staticClass: "image",
                              attrs: {
                                src: pItem.urlPathPrefix + pItem.urlPath,
                                "preview-src-list": [
                                  pItem.urlPathPrefix + pItem.urlPath,
                                ],
                              },
                            })
                          }),
                          1
                        )
                      : _c("EmptyImage", { staticClass: "mb-10" }),
                  ],
                  1
                )
              }),
              _c(
                "div",
                { staticClass: "mt-20" },
                [_c("ExtendForm", { attrs: { value: _vm.activityObj } })],
                1
              ),
            ],
            2
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }