var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "write-off" },
    [
      _c(
        "el-tabs",
        { attrs: { value: _vm.activeName } },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "活动申请", name: "first" } },
            [
              _c("ActivityApply", {
                attrs: { disabled: _vm.formConfig.code === "view" },
                on: {
                  changeAct: _vm.changeAct,
                  setFielValue: _vm.setFielValue,
                },
                model: {
                  value: _vm.formData.tpmAuditActReqVos,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "tpmAuditActReqVos", $$v)
                  },
                  expression: "formData.tpmAuditActReqVos",
                },
              }),
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "核销申请", name: "third" } },
            [
              _c(
                "div",
                { staticClass: "form-item" },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 10 } },
                    [
                      _c(
                        "el-col",
                        { staticClass: "flex", attrs: { span: 12 } },
                        [
                          _c("div", { staticClass: "label-box" }, [
                            _vm._v("核销申请名称"),
                          ]),
                          _c("el-input", {
                            attrs: {
                              placeholder: "请填写核销申请名称",
                              disabled: _vm.formConfig.code === "view",
                            },
                            model: {
                              value: _vm.formData.auditName,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "auditName", $$v)
                              },
                              expression: "formData.auditName",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { staticClass: "flex", attrs: { span: 12 } },
                        [
                          _c("div", { staticClass: "label-box" }, [
                            _vm._v("核销单号"),
                          ]),
                          _c("el-input", {
                            attrs: { placeholder: "核销单号", disabled: "" },
                            model: {
                              value: _vm.formData.auditCode,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "auditCode", $$v)
                              },
                              expression: "formData.auditCode",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { staticClass: "flex", attrs: { span: 12 } },
                        [
                          _c("div", { staticClass: "label-box" }, [
                            _vm._v("核销类型"),
                          ]),
                          _c(
                            "el-select",
                            {
                              staticStyle: { flex: "1" },
                              attrs: {
                                disabled: _vm.formConfig.code === "view",
                                placeholder: "请选择核销类型",
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.getWriteOffList($event)
                                },
                              },
                              model: {
                                value: _vm.formData.ext4,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formData, "ext4", $$v)
                                },
                                expression: "formData.ext4",
                              },
                            },
                            _vm._l(_vm.writeOffList, function (item) {
                              return _c("el-option", {
                                key: item.dictCode,
                                attrs: {
                                  label: item.dictValue,
                                  value: item.dictCode,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { staticClass: "flex", attrs: { span: 24 } },
                        [
                          _c("div", { staticClass: "label-box" }, [
                            _vm._v("备注"),
                          ]),
                          _c("el-input", {
                            attrs: {
                              type: "textarea",
                              placeholder: "请填写备注",
                              rows: "3",
                              disabled: _vm.formConfig.code === "view",
                            },
                            model: {
                              value: _vm.formData.remarks,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "remarks", $$v)
                              },
                              expression: "formData.remarks",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("WriteOffDetails", {
                attrs: {
                  getFieldValue: _vm.getFieldValue,
                  code: _vm.formConfig.code,
                  formRole: _vm.formRole,
                  disabled: _vm.formConfig.code === "view",
                  tastingReqVos: _vm.formData.tastingReqVos,
                  auditId: _vm.formData.id,
                },
                on: {
                  setFielValue: _vm.setFielValue,
                  changeTasting: _vm.changeTasting,
                },
                model: {
                  value: _vm.formData.detailVos,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "detailVos", $$v)
                  },
                  expression: "formData.detailVos",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      !_vm.noButton
        ? _c(
            "div",
            { staticClass: "dialog-footer" },
            [
              _vm.formConfig.code !== "view"
                ? _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                        icon: "el-icon-check",
                        loading: _vm.$store.state.buttonApiIsLoading,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.submit(1)
                        },
                      },
                    },
                    [_vm._v("保存")]
                  )
                : _vm._e(),
              _vm.formConfig.code !== "view"
                ? _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                        icon: "el-icon-clocheckse",
                        loading: _vm.$store.state.buttonApiIsLoading,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.submit(2)
                        },
                      },
                    },
                    [_vm._v("保存并提交")]
                  )
                : _vm._e(),
              _c(
                "el-button",
                {
                  attrs: {
                    icon: "el-icon-close",
                    loading: _vm.$store.state.buttonApiIsLoading,
                    type: "danger",
                  },
                  on: { click: _vm.closeModal },
                },
                [_vm._v("关闭")]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }