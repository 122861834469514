<template>
  <div class="all-container">
    <el-tabs v-model="activeTab" @tab-click="tabClick" :tab-position="'left'" class="left" v-if="tabList && tabList.length > 0">
      <el-tab-pane :label="item.activityName" :name="item.id" v-for="(item, index) in tabList" :key="index"></el-tab-pane>
    </el-tabs>
  <div class="body" v-if="costActivityObj&&costActivityObj.activityCode">
    <Title>基本信息</Title>
    <el-form label-width="100px">
      <el-row>
        <el-col :span="8">
          <el-form-item label="活动编码">
            <TextEllipsis :text="costActivityObj.activityCode"></TextEllipsis>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="活动名称">
            <TextEllipsis :text="costActivityObj.activityName"></TextEllipsis>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="活动类型">
            <TextEllipsis :text="'费用活动'"></TextEllipsis>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="活动执行时间">
            <template v-if="costActivityObj.activityStartTime&&costActivityObj.activityEndTime">
            <TextEllipsis :text="costActivityObj.activityStartTime + ' 至 ' +  costActivityObj.activityEndTime"></TextEllipsis>
            </template>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <Title>活动信息</Title>
    <el-form label-width="100px">
      <el-row>
        <el-col :span="8">
          <el-form-item label="预估销售额">
            <TextEllipsis :text="costActivityObj.salesVolume"></TextEllipsis>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="费用申请金额">
            <TextEllipsis :text="costActivityObj.applyAmount"></TextEllipsis>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="活动需求">
            <TextEllipsis :text="costActivityObj.activityRequireDesc"></TextEllipsis>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="活动描述">
            <TextEllipsis :text="costActivityObj.activityDesc"></TextEllipsis>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <Title>执行结果</Title>
    <div v-if="costActivityObj.activityRequireReqVoList&&costActivityObj.activityRequireReqVoList.length>0">
      <el-form label-width="100px">
        <el-form-item :label="item.activityRequireName" v-for="(item, index) in costActivityObj.activityRequireReqVoList" :key="index">
          <div v-if="item.pictureList&&item.pictureList.length>0">
            <el-image class="image" v-for="(pItem, pIndex) in item.pictureList"
                      :src="pItem.urlPathPrefix + pItem.urlPath"
                      :preview-src-list="[pItem.urlPathPrefix + pItem.urlPath]"
                      :key="pIndex">
            </el-image>
          </div>
          <EmptyImage class="mb-10" v-else></EmptyImage>
        </el-form-item>
      </el-form>
    </div>
    <!-- <div v-if="costActivityObj.executionTypeList.length>0">
      <el-image v-for="(item,index) in costActivityObj.executionTypeList"
                :src="item.picPath+item.picUrl"
                :preview-src-list="costActivityObj.executionTypeList"
                :key="index">
      </el-image>
    </div>
    <EmptyImage v-else></EmptyImage> -->
    <div class="mt-20">
      <ExtendForm :value="costActivityObj"></ExtendForm>
    </div>
  </div>
  </div>
</template>

<script>
import TextEllipsis from '../../../../../../../../../components/text_ellipsis/index.vue';
import Title from '../../../../../../../../../components/title';
import EmptyImage from '../../../../../../../../../components/empty';
import request from '../../../../../../../../../utils/request';
/** @desc 扩展表单 */
import ExtendForm from './extend_form.vue';

const isObject = (value) => Object.prototype.toString.call(value) === '[object Object]';
export default {
  enName: '费用活动',
  components: {
    TextEllipsis, Title, EmptyImage, ExtendForm,
  },
  props: {
    value: Object,
  },
  watch: {
    value: {
      handler(curVal) {
        if (curVal && curVal.id) {
          this.id = curVal.id;
          this.activityType = curVal.activityType;
          this.stepCode = curVal.stepCode;
          this.getTabList();
        }
        // if (curVal && curVal.stepCostActivityExecution && isObject(curVal.stepCostActivityExecution)) {
        //   this.costActivityObj = {
        //     ...this.costActivityObj,
        //     ...curVal.stepCostActivityExecution,
        //     executionTypeList: curVal.stepCostActivityExecution.executionTypeList || [],
        //   };
        // }
      },
      immediate: true,
      deep: true,
    },
  },
  data() {
    return {
      id: '',
      activityType: '',
      stepCode: '',
      activeTab: '',
      tabList: [],
      costActivityObj: {},
    };
  },
  methods: {
    // tab切换
    tabClick(tab) {
      this.getData(tab.name);
    },
    // 获取tab数据
    getTabList() {
      const params = {
        id: this.id,
        activityType: this.activityType,
        stepCode: this.stepCode,
      };
      request.post('/sfa/sfaVisitController/findVisitActivityExecutionList', params).then((res) => {
        if (!res.success || !res.result) return false;
        this.tabList = res.result || [];
        if (this.tabList && this.tabList.length > 0) {
          this.activeTab = this.tabList[0].id;
          this.getData(this.tabList[0].id);
        }
      });
    },
    // 获取活动执行结果数据
    getData(activityId) {
      const params = {
        visitPlanInfoId: this.id,
        activityType: this.activityType,
        activityExecutionId: activityId,
      };
      request.post('/sfa/sfaWorkDailyController/loadActExecutionEditPageForWorkbench', params).then((res) => {
        if (!res.success || !res.result) return false;
        this.costActivityObj = res.result;
        let activityRequireNames = [];
        const { activityRequireReqVoList } = res.result;
        if (activityRequireReqVoList && activityRequireReqVoList.length > 0) {
          activityRequireNames = activityRequireReqVoList.map((v) => (v.activityRequireName));
        }
        this.costActivityObj.activityRequireDesc = activityRequireNames.join('、');
      });
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .el-tabs--left{
  overflow: initial;
}
.all-container{
  display: flex;
  flex-direction: row;
  align-items: stretch;
  .body{
    flex: 1;
  }
}
.image{
  width: 160px;
  height: 160px;
}
.mt-20{
  margin-top: 20px;
}
.mb-10{
  margin-bottom: 10px;
}
</style>
