<script>
import TablePage from '@/found/components/table_page';
import request from '../../../../../../../utils/request';

export default {
  extends: TablePage,
  props: {
    formConfig: Object,
  },
  components: {},
  data() {
    return {};
  },
  created() {},
  methods: {},
  mounted() {
    if (this.formConfig.code === 'add') {
      this.params = { actDetailCode: this.formConfig.row.actDetailCode };
    } else {
      this.params = { auditId: this.formConfig.auditId };
    }
    this.getConfigList('scan_record_list', true, false, 'CRM20201126000000098');
  },
};
</script>
