var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "details" },
    [
      _c("vxe-toolbar", {
        scopedSlots: _vm._u([
          {
            key: "buttons",
            fn: function () {
              return [
                !_vm.disabled
                  ? _c(
                      "el-link",
                      {
                        attrs: { icon: "el-icon-plus" },
                        on: { click: _vm.openDetails },
                      },
                      [_vm._v("新增明细")]
                    )
                  : _vm._e(),
                _c(
                  "el-link",
                  {
                    attrs: { icon: "el-icon-document" },
                    on: {
                      click: function ($event) {
                        return _vm.openVerification()
                      },
                    },
                  },
                  [_vm._v("核销资料")]
                ),
                !_vm.disabled
                  ? _c(
                      "el-link",
                      {
                        staticClass: "red",
                        attrs: { icon: "el-icon-delete" },
                        on: { click: _vm.clearTable },
                      },
                      [_vm._v("清空")]
                    )
                  : _vm._e(),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c(
        "vxe-table",
        {
          ref: "xTable",
          attrs: { border: "", "show-overflow": "", data: _vm.value },
          on: {
            "checkbox-change": _vm.checkboxChange,
            "checkbox-all": _vm.checkboxAll,
          },
        },
        [
          _vm._l(_vm.columns, function (config) {
            return [
              config.field === "auditApplyAmount"
                ? _c(
                    "vxe-table-column",
                    _vm._b(
                      {
                        key: config.id,
                        attrs: { "min-width": config.minWidth || 150 },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var row = ref.row
                                var rowIndex = ref.rowIndex
                                return [
                                  _c("vxe-input", {
                                    attrs: {
                                      type: "number",
                                      min: 0,
                                      max: _vm.maxFn(row),
                                      disabled: _vm.disabled,
                                    },
                                    on: {
                                      input: function ($event) {
                                        return _vm.inputAuditApplyAount(
                                          $event,
                                          row,
                                          rowIndex
                                        )
                                      },
                                    },
                                    model: {
                                      value: row[config.field],
                                      callback: function ($$v) {
                                        _vm.$set(row, config.field, $$v)
                                      },
                                      expression: "row[config.field]",
                                    },
                                  }),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      },
                      "vxe-table-column",
                      config,
                      false
                    )
                  )
                : config.field === "ext77"
                ? _c(
                    "vxe-table-column",
                    _vm._b(
                      {
                        key: config.id,
                        attrs: { "min-width": config.minWidth || 150 },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var row = ref.row
                                return [
                                  _c("vxe-input", {
                                    attrs: {
                                      maxlength: "100",
                                      disabled: _vm.disabled,
                                    },
                                    model: {
                                      value: row[config.field],
                                      callback: function ($$v) {
                                        _vm.$set(row, config.field, $$v)
                                      },
                                      expression: "row[config.field]",
                                    },
                                  }),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      },
                      "vxe-table-column",
                      config,
                      false
                    )
                  )
                : config.field === "ext31"
                ? _c(
                    "vxe-table-column",
                    _vm._b(
                      {
                        key: config.id,
                        attrs: { "min-width": config.minWidth || 150 },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var row = ref.row
                                return [
                                  _c("vxe-input", {
                                    attrs: { disabled: _vm.disabled },
                                    model: {
                                      value: row[config.field],
                                      callback: function ($$v) {
                                        _vm.$set(row, config.field, $$v)
                                      },
                                      expression: "row[config.field]",
                                    },
                                  }),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      },
                      "vxe-table-column",
                      config,
                      false
                    )
                  )
                : config.field === "ext18"
                ? _c(
                    "vxe-table-column",
                    _vm._b(
                      {
                        key: config.id,
                        attrs: { "min-width": config.minWidth || 150 },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var row = ref.row
                                return [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        placeholder: "请选择",
                                        disabled: _vm.disabled,
                                      },
                                      model: {
                                        value: row[config.field],
                                        callback: function ($$v) {
                                          _vm.$set(row, config.field, $$v)
                                        },
                                        expression: "row[config.field]",
                                      },
                                    },
                                    [
                                      _c("el-option", {
                                        attrs: {
                                          label: "其他",
                                          value: "00000",
                                        },
                                      }),
                                      _c("el-option", {
                                        attrs: {
                                          label: "客户",
                                          value: "00001",
                                        },
                                      }),
                                      _c("el-option", {
                                        attrs: {
                                          label: "供应商",
                                          value: "00002",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      },
                      "vxe-table-column",
                      config,
                      false
                    )
                  )
                : config.field === "ext15"
                ? _c(
                    "vxe-table-column",
                    _vm._b(
                      {
                        key: config.id,
                        attrs: { "min-width": config.minWidth || 150 },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var row = ref.row
                                return [
                                  _c("vxe-input", {
                                    attrs: {
                                      disabled: _vm.disabled || !row.ext4,
                                    },
                                    model: {
                                      value: row[config.field],
                                      callback: function ($$v) {
                                        _vm.$set(row, config.field, $$v)
                                      },
                                      expression: "row[config.field]",
                                    },
                                  }),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      },
                      "vxe-table-column",
                      config,
                      false
                    )
                  )
                : config.field === "ext16"
                ? _c(
                    "vxe-table-column",
                    _vm._b(
                      {
                        key: config.id,
                        attrs: { "min-width": config.minWidth || 150 },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var row = ref.row
                                return [
                                  _c("vxe-input", {
                                    attrs: {
                                      disabled: _vm.disabled || !row.ext4,
                                    },
                                    model: {
                                      value: row[config.field],
                                      callback: function ($$v) {
                                        _vm.$set(row, config.field, $$v)
                                      },
                                      expression: "row[config.field]",
                                    },
                                  }),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      },
                      "vxe-table-column",
                      config,
                      false
                    )
                  )
                : config.field === "remarks"
                ? _c(
                    "vxe-table-column",
                    _vm._b(
                      {
                        key: config.id,
                        attrs: { "min-width": config.minWidth || 150 },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var row = ref.row
                                return [
                                  _c("vxe-input", {
                                    attrs: {
                                      type: "textarea",
                                      disabled: _vm.disabled,
                                    },
                                    model: {
                                      value: row[config.field],
                                      callback: function ($$v) {
                                        _vm.$set(row, config.field, $$v)
                                      },
                                      expression: "row[config.field]",
                                    },
                                  }),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      },
                      "vxe-table-column",
                      config,
                      false
                    )
                  )
                : config.field === "ext10"
                ? _c(
                    "vxe-table-column",
                    _vm._b(
                      {
                        key: config.id,
                        attrs: { "min-width": config.minWidth || 150 },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var row = ref.row
                                return [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        placeholder: "请选择",
                                        disabled: "",
                                      },
                                      model: {
                                        value: row[config.field],
                                        callback: function ($$v) {
                                          _vm.$set(row, config.field, $$v)
                                        },
                                        expression: "row[config.field]",
                                      },
                                    },
                                    [
                                      _c("el-option", {
                                        attrs: { label: "是", value: "Y" },
                                      }),
                                      _c("el-option", {
                                        attrs: { label: "否", value: "N" },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      },
                      "vxe-table-column",
                      config,
                      false
                    )
                  )
                : config.field === "ext76"
                ? _c(
                    "vxe-table-column",
                    _vm._b(
                      {
                        key: config.id,
                        attrs: { "min-width": config.minWidth || 150 },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var row = ref.row
                                return [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        placeholder: "请选择",
                                        disabled:
                                          _vm.formRole === "update2"
                                            ? false
                                            : true,
                                      },
                                      model: {
                                        value: row[config.field],
                                        callback: function ($$v) {
                                          _vm.$set(row, config.field, $$v)
                                        },
                                        expression: "row[config.field]",
                                      },
                                    },
                                    [
                                      _c("el-option", {
                                        attrs: { label: "是", value: "Y" },
                                      }),
                                      _c("el-option", {
                                        attrs: { label: "否", value: "N" },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      },
                      "vxe-table-column",
                      config,
                      false
                    )
                  )
                : config.field === "payType"
                ? _c(
                    "vxe-table-column",
                    _vm._b(
                      {
                        key: config.id,
                        attrs: { "min-width": config.minWidth || 150 },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var row = ref.row
                                return [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        placeholder: "请选择",
                                        disabled: "",
                                      },
                                      model: {
                                        value: row[config.field],
                                        callback: function ($$v) {
                                          _vm.$set(row, config.field, $$v)
                                        },
                                        expression: "row[config.field]",
                                      },
                                    },
                                    _vm._l(row.payTypes, function (item, k) {
                                      return _c("el-option", {
                                        key: k,
                                        attrs: {
                                          label: item.dictKey,
                                          value: item.dictValue,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      },
                      "vxe-table-column",
                      config,
                      false
                    )
                  )
                : config.field === "productLevelCode"
                ? _c(
                    "vxe-table-column",
                    _vm._b(
                      {
                        key: config.id,
                        attrs: { "min-width": config.minWidth || 200 },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var row = ref.row
                                var rowIndex = ref.rowIndex
                                return [
                                  !_vm.disabled
                                    ? _c(
                                        "el-select",
                                        {
                                          attrs: {
                                            filterable: "",
                                            remote: "",
                                            placeholder: "产品层级",
                                            "remote-method": function (v) {
                                              return _vm.remoteMethod(
                                                "lev",
                                                rowIndex,
                                                v
                                              )
                                            },
                                            loading: _vm.loading,
                                            clearable: "",
                                            disabled: row.payType !== "4",
                                          },
                                          on: {
                                            focus: function () {
                                              return _vm.remoteMethod(
                                                "lev",
                                                rowIndex
                                              )
                                            },
                                            change: function (v) {
                                              return _vm.levelChange(
                                                v,
                                                rowIndex
                                              )
                                            },
                                          },
                                          model: {
                                            value: row.productLevelCode,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                row,
                                                "productLevelCode",
                                                $$v
                                              )
                                            },
                                            expression: "row.productLevelCode",
                                          },
                                        },
                                        _vm._l(
                                          row.levelOptions,
                                          function (item) {
                                            return _c("el-option", {
                                              key: item.value,
                                              attrs: {
                                                label: item.productLevelName,
                                                value: item.productLevelCode,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      )
                                    : _c("span", [
                                        _vm._v(_vm._s(row.productLevelName)),
                                      ]),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      },
                      "vxe-table-column",
                      config,
                      false
                    )
                  )
                : config.field === "replenishmentProductCodes"
                ? _c(
                    "vxe-table-column",
                    _vm._b(
                      {
                        key: config.id,
                        attrs: { "min-width": config.minWidth || 260 },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var row = ref.row
                                var rowIndex = ref.rowIndex
                                return [
                                  !_vm.disabled
                                    ? _c(
                                        "el-select",
                                        {
                                          attrs: {
                                            filterable: "",
                                            remote: "",
                                            "reserve-keyword": "",
                                            placeholder: "产品数据",
                                            "remote-method": function (v) {
                                              return _vm.remoteMethod(
                                                "pro",
                                                rowIndex,
                                                v
                                              )
                                            },
                                            loading: _vm.loading,
                                            clearable: "",
                                            multiple: "",
                                            "collapse-tags": "",
                                            disabled: row.payType !== "4",
                                          },
                                          on: {
                                            focus: function () {
                                              return _vm.remoteMethod(
                                                "pro",
                                                rowIndex
                                              )
                                            },
                                            change: function (v) {
                                              return _vm.productChange(
                                                v,
                                                rowIndex
                                              )
                                            },
                                          },
                                          model: {
                                            value:
                                              row.replenishmentProductCodes,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                row,
                                                "replenishmentProductCodes",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "row.replenishmentProductCodes",
                                          },
                                        },
                                        _vm._l(
                                          row.productOptions,
                                          function (item) {
                                            return _c("el-option", {
                                              key: item.value,
                                              attrs: {
                                                label: item.productName,
                                                value: item.productCode,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      )
                                    : _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            (row.replenishmentProductList || [])
                                              .map(function (v) {
                                                return v.productName
                                              })
                                              .join(",")
                                          )
                                        ),
                                      ]),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      },
                      "vxe-table-column",
                      config,
                      false
                    )
                  )
                : _c(
                    "vxe-table-column",
                    _vm._b(
                      {
                        key: config.id,
                        attrs: { "min-width": config.minWidth || 150 },
                      },
                      "vxe-table-column",
                      config,
                      false
                    )
                  ),
            ]
          }),
          _c("vxe-table-column", {
            attrs: {
              title: "操作",
              fixed: "right",
              width: "150",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  var rowIndex = ref.rowIndex
                  return [
                    _c(
                      "div",
                      { staticClass: "setting-btn" },
                      [
                        row.ext73 === "Y"
                          ? _c(
                              "el-link",
                              {
                                attrs: { type: "primary" },
                                on: {
                                  click: function ($event) {
                                    return _vm.executionLookFn(row, rowIndex)
                                  },
                                },
                              },
                              [_vm._v("执行查看")]
                            )
                          : _vm._e(),
                        row.categoriesCode === "TRLX0043"
                          ? _c(
                              "el-link",
                              {
                                attrs: { type: "primary" },
                                on: {
                                  click: function ($event) {
                                    return _vm.viewVisitRecords(row, rowIndex)
                                  },
                                },
                              },
                              [_vm._v("查看拜访记录")]
                            )
                          : _vm._e(),
                        _vm.columns.length > 0 && !_vm.disabled
                          ? _c(
                              "el-popconfirm",
                              {
                                attrs: {
                                  title: "此操作将永久删除该数据？",
                                  "confirm-button-type": "text",
                                },
                                on: {
                                  confirm: function ($event) {
                                    return _vm.removeRow(row, rowIndex)
                                  },
                                },
                              },
                              [
                                _c(
                                  "el-link",
                                  {
                                    staticClass: "red",
                                    attrs: { slot: "reference" },
                                    slot: "reference",
                                  },
                                  [_vm._v("删除")]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        2
      ),
      _vm.tastingReqVos.length > 0
        ? _c(
            "div",
            { staticClass: "tasting" },
            [
              _c(
                "vxe-table",
                {
                  ref: "xTable",
                  attrs: {
                    border: "",
                    "show-overflow": "",
                    data: _vm.tastingReqVos,
                  },
                  on: {
                    "checkbox-change": _vm.checkboxChange,
                    "checkbox-all": _vm.checkboxAll,
                  },
                },
                [
                  _vm._l(_vm.columns1, function (config) {
                    return [
                      config.field === "num"
                        ? _c(
                            "vxe-table-column",
                            _vm._b(
                              {
                                key: config.id,
                                attrs: { "min-width": config.minWidth || 150 },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var row = ref.row
                                        var rowIndex = ref.rowIndex
                                        return [
                                          _c("vxe-input", {
                                            attrs: {
                                              type: "number",
                                              min: 0,
                                              max: row.tastingNum,
                                              disabled:
                                                _vm.disabled || !row.isTarget,
                                            },
                                            on: {
                                              input: function ($event) {
                                                return _vm.inputAmount(
                                                  $event,
                                                  row,
                                                  rowIndex
                                                )
                                              },
                                            },
                                            model: {
                                              value: row[config.field],
                                              callback: function ($$v) {
                                                _vm.$set(row, config.field, $$v)
                                              },
                                              expression: "row[config.field]",
                                            },
                                          }),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              },
                              "vxe-table-column",
                              config,
                              false
                            )
                          )
                        : config.field === "payType"
                        ? _c(
                            "vxe-table-column",
                            _vm._b(
                              {
                                key: config.id,
                                attrs: { "min-width": config.minWidth || 150 },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var row = ref.row
                                        return [
                                          _c(
                                            "el-select",
                                            {
                                              attrs: {
                                                placeholder: "请选择",
                                                disabled: "",
                                              },
                                              model: {
                                                value: row[config.field],
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    row,
                                                    config.field,
                                                    $$v
                                                  )
                                                },
                                                expression: "row[config.field]",
                                              },
                                            },
                                            _vm._l(
                                              _vm.tastingPayTypeList,
                                              function (item, k) {
                                                return _c("el-option", {
                                                  key: k,
                                                  attrs: {
                                                    label: item.dictValue,
                                                    value: item.dictCode,
                                                  },
                                                })
                                              }
                                            ),
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              },
                              "vxe-table-column",
                              config,
                              false
                            )
                          )
                        : _c(
                            "vxe-table-column",
                            _vm._b(
                              {
                                key: config.id,
                                attrs: { "min-width": config.minWidth || 150 },
                              },
                              "vxe-table-column",
                              config,
                              false
                            )
                          ),
                    ]
                  }),
                  _c("vxe-table-column", {
                    attrs: {
                      title: "操作",
                      fixed: "right",
                      width: "150",
                      align: "center",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            var rowIndex = ref.rowIndex
                            return [
                              _c(
                                "div",
                                { staticClass: "setting-btn" },
                                [
                                  _c(
                                    "el-link",
                                    {
                                      attrs: { type: "primary" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.showModal(row, rowIndex)
                                        },
                                      },
                                    },
                                    [_vm._v("扫码记录")]
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2791428807
                    ),
                  }),
                ],
                2
              ),
            ],
            1
          )
        : _vm._e(),
      _c("SelectConfig", {
        ref: "selectConfig",
        on: { onGetSelect: _vm.onGetSelect },
      }),
      _c(
        "Modal",
        {
          attrs: {
            modalConfig: _vm.modalConfig,
            visible: _vm.modalConfig.visible,
          },
          on: {
            "update:modalConfig": function ($event) {
              _vm.modalConfig = $event
            },
            "update:modal-config": function ($event) {
              _vm.modalConfig = $event
            },
            "update:visible": function ($event) {
              return _vm.$set(_vm.modalConfig, "visible", $event)
            },
            onClose: _vm.closeModal,
          },
        },
        [
          _c(_vm.formName, {
            ref: "modalForm",
            tag: "component",
            attrs: { formConfig: _vm.formConfig, dataObj: _vm.dataObj },
            on: {
              getResourceList: _vm.getResourceList,
              setFielValue: _vm.setFielValue,
              setPersonValue: _vm.setPersonValue,
            },
          }),
          _c(
            "div",
            { staticClass: "dialog-footer" },
            [
              _vm.isScan
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "danger" },
                      on: { click: _vm.confirmResource },
                    },
                    [_vm._v("确定")]
                  )
                : _vm._e(),
              _c(
                "el-button",
                {
                  attrs: { icon: "el-icon-close", type: "danger" },
                  on: { click: _vm.closeModal },
                },
                [_vm._v("关闭")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }