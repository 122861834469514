var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "apply" },
    [
      !_vm.disabled
        ? _c("vxe-toolbar", {
            scopedSlots: _vm._u(
              [
                {
                  key: "buttons",
                  fn: function () {
                    return [
                      _c(
                        "el-link",
                        {
                          attrs: { icon: "el-icon-plus" },
                          on: { click: _vm.openActivity },
                        },
                        [_vm._v("新增活动")]
                      ),
                      _c(
                        "el-link",
                        {
                          attrs: { icon: "el-icon-document" },
                          on: { click: _vm.openLog },
                        },
                        [_vm._v("流程日志")]
                      ),
                      _c(
                        "el-link",
                        {
                          staticClass: "red",
                          attrs: { icon: "el-icon-delete" },
                          on: { click: _vm.clearTable },
                        },
                        [_vm._v("清空")]
                      ),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              1455465248
            ),
          })
        : _vm._e(),
      _c(
        "vxe-table",
        {
          ref: "xTable",
          attrs: { border: "", "show-overflow": "", data: _vm.value },
          on: { "radio-change": _vm.checkboxChange },
        },
        [
          _vm._l(_vm.columns, function (config) {
            return _c(
              "vxe-table-column",
              _vm._b(
                {
                  key: config.id,
                  attrs: { "min-width": config.minWidth || 100 },
                  scopedSlots: _vm._u(
                    [
                      config.field === "actCode"
                        ? {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                _c(
                                  "el-link",
                                  {
                                    attrs: { type: "primary" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.cellClick(row)
                                      },
                                    },
                                  },
                                  [_vm._v(_vm._s(row.actCode))]
                                ),
                              ]
                            },
                          }
                        : null,
                    ],
                    null,
                    true
                  ),
                },
                "vxe-table-column",
                config,
                false
              )
            )
          }),
          _c("vxe-table-column", {
            attrs: {
              title: "操作",
              fixed: "right",
              width: "100",
              align: "center",
              visible: _vm.columns.length > 0 && !_vm.disabled,
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  var rowIndex = ref.rowIndex
                  return [
                    _c(
                      "div",
                      { staticClass: "setting-btn" },
                      [
                        _c(
                          "el-popconfirm",
                          {
                            attrs: {
                              title: "此操作将永久删除该数据？",
                              "confirm-button-type": "text",
                            },
                            on: {
                              confirm: function ($event) {
                                return _vm.removeRow(row, rowIndex)
                              },
                            },
                          },
                          [
                            _c(
                              "el-link",
                              {
                                staticClass: "red",
                                attrs: { slot: "reference" },
                                slot: "reference",
                              },
                              [_vm._v("删除")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        2
      ),
      _c("SelectConfig", {
        ref: "selectConfig",
        on: { onGetSelect: _vm.onGetSelect },
      }),
      _c(
        "Modal",
        {
          staticStyle: { "z-index": "2000" },
          attrs: {
            modalConfig: _vm.modalConfig,
            visible: _vm.modalConfig.visible,
          },
          on: {
            "update:modalConfig": function ($event) {
              _vm.modalConfig = $event
            },
            "update:modal-config": function ($event) {
              _vm.modalConfig = $event
            },
            "update:visible": function ($event) {
              return _vm.$set(_vm.modalConfig, "visible", $event)
            },
            onClose: _vm.closeModal,
          },
        },
        [
          _c(_vm.formName, {
            ref: "modalForm",
            tag: "component",
            attrs: { formConfig: _vm.formConfig },
            on: { onClose: _vm.closeModal },
          }),
          _c(
            "div",
            { staticClass: "dialog-footer" },
            [
              _c(
                "el-button",
                {
                  attrs: { icon: "el-icon-close", type: "danger" },
                  on: { click: _vm.closeModal },
                },
                [_vm._v("关闭")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }