<template>
  <div class="all-container">
    <el-tabs v-model="activeTab" @tab-click="tabClick" :tab-position="'left'" class="left" v-if="tabList && tabList.length > 0">
      <el-tab-pane :label="item.actName" :name="item.actDetailCode" v-for="(item, index) in tabList" :key="index"></el-tab-pane>
    </el-tabs>
    <div class="body" v-if="activityObj&&activityObj.actDetailCode">
      <Title>基本信息</Title>
      <el-form label-width="110px">
        <el-row>
          <el-col :span="8">
            <el-form-item label="活动编码">
              <TextEllipsis :text="activityObj.actDetailCode"></TextEllipsis>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="活动名称">
              <TextEllipsis :text="activityObj.actName"></TextEllipsis>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="支付方式">
              <TextEllipsis :text="activityObj.payTypeName"></TextEllipsis>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="总活动金额">
              <TextEllipsis :text="activityObj.actAmount+'元'"></TextEllipsis>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="总使用金额">
              <TextEllipsis :text="activityObj.usedAmount+'元'"></TextEllipsis>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="本店已使用金额">
              <TextEllipsis :text="activityObj.currentUsedAmount+'元'"></TextEllipsis>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="活动状态">
              <TextEllipsis :text="activityObj.actStatusName"></TextEllipsis>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="活动开始时间">
              <TextEllipsis :text="activityObj.beginDate"></TextEllipsis>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="活动结束时间">
              <TextEllipsis :text="activityObj.endDate"></TextEllipsis>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <template v-if="activityObj.pdfUrl">
        <Title>协议信息</Title>
        <el-button type="primary" style="margin-bottom: 20px;" @click="viewTreaty">查看协议</el-button>
      </template>
      <template v-if="activityObj.distributionOrder">
        <Title>分销订单数据</Title>
        <div class="product-container">
          <div class="product-title-item">本品</div>
          <vxe-table class="product-table" :data="activityObj.distributionOrder.bpProductList">
            <vxe-table-column title="序号" width="100px">
              <template v-slot="{rowIndex}">{{rowIndex+1}}</template>
            </vxe-table-column>
            <vxe-table-column title="产品编码" field="productCode" min-width="100px" show-overflow></vxe-table-column>
            <vxe-table-column title="产品名称" field="productName" min-width="100px" show-overflow></vxe-table-column>
            <vxe-table-column title="单价(元)" field="price" min-width="100px" show-overflow></vxe-table-column>
            <vxe-table-column title="单位" field="unitName" min-width="100px" show-overflow></vxe-table-column>
            <vxe-table-column title="数量" field="quantity" min-width="100px" show-overflow></vxe-table-column>
          </vxe-table>
          <div class="product-title-item">赠品</div>
          <vxe-table class="product-table" :data="activityObj.distributionOrder.zpProductList">
            <vxe-table-column title="序号" width="100px">
              <template v-slot="{rowIndex}">{{rowIndex+1}}</template>
            </vxe-table-column>
            <vxe-table-column title="产品编码" field="productCode" min-width="100px" show-overflow></vxe-table-column>
            <vxe-table-column title="产品名称" field="productName" min-width="100px" show-overflow></vxe-table-column>
            <vxe-table-column title="单价(元)" field="price" min-width="100px" show-overflow></vxe-table-column>
            <vxe-table-column title="单位" field="unitName" min-width="100px" show-overflow></vxe-table-column>
            <vxe-table-column title="数量" field="quantity" min-width="100px" show-overflow></vxe-table-column>
          </vxe-table>
        </div>
      </template>
      <template v-if="activityObj.actCollectData&&activityObj.actCollectData.length>0">
        <Title>活动数据采集信息</Title>
        <div v-for="(item, index) in activityObj.actCollectData.collectFormList" :key="index">
          <Title>{{item.exampleName}}</Title>
          <div v-if="item.attachmentList&&item.attachmentList.length>0">
            <el-image class="image" v-for="(pItem, pIndex) in item.attachmentList"
                      :src="pItem.urlPathPrefix + pItem.urlPath"
                      :preview-src-list="[pItem.urlPathPrefix + pItem.urlPath]"
                      :key="pIndex">
            </el-image>
          </div>
          <EmptyImage class="mb-10" v-else></EmptyImage>
        </div>
      </template>
    </div>
    <el-drawer title="查看协议" size="70%" :visible.sync="showDialog" append-to-body>
      <pdf :src="activityObj.pdfUrl" :page="curPage" @num-pages="getPageNum"></pdf>
      <div class="footer">
        <el-button-group class="page">
          <el-button @click="prevPage" icon="el-icon-arrow-left" :disabled="curPage === 1">上一页</el-button>
          <el-button @click="nextPage" icon='el-icon-arrow-right' :disabled="!totalPage || curPage === totalPage">下一页</el-button>
        </el-button-group>
        <el-button-group class="download">
          <el-button type="primary" icon="el-icon-download" @click="download">下载</el-button>
          <el-button type="danger" icon="el-icon-close" @click="closeDialog">关闭</el-button>
        </el-button-group>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import pdf from 'vue-pdf';
import TextEllipsis from '../../../../../../../../../components/text_ellipsis/index.vue';
import Title from '../../../../../../../../../components/title';
import EmptyImage from '../../../../../../../../../components/empty';
import request from '../../../../../../../../../utils/request';

export default {
  enName: 'tpm活动',
  components: {
    TextEllipsis, Title, EmptyImage, pdf,
  },
  props: {
    value: Object,
  },
  watch: {
    value: {
      handler(curVal) {
        if (curVal && curVal.id) {
          this.id = curVal.id;
          this.clientCode = curVal.clientCode;
          this.activityType = curVal.activityType;
          this.stepCode = curVal.stepCode;
          this.getTabList();
        }
      },
      immediate: true,
      deep: true,
    },
  },
  data() {
    return {
      id: '',
      clientCode: '',
      activityType: '',
      stepCode: '',
      activeTab: '',
      tabList: [],
      activityObj: {},
      pictureList: [],
      showDialog: false,
      curPage: 1, // 当前页
      totalPage: 1, // 总页数
    };
  },
  methods: {
    // tab切换
    tabClick(tab) {
      this.activeTab = tab.name;
      this.getData(tab.name);
    },
    // 获取tab数据
    getTabList() {
      const params = {
        visitPlanInfoId: this.id,
        terminalCode: this.clientCode,
      };
      request.post('/sfa/sfaWorkDailyController/loadVisitStepTpmReport', params).then((res) => {
        if (!res.success || !res.result) return false;
        this.tabList = res.result || [];
        if (this.tabList && this.tabList.length > 0) {
          this.activeTab = this.tabList[0].actDetailCode;
          this.getData(this.tabList[0].actDetailCode);
        }
      });
    },
    // 获取活动执行结果数据
    getData(activityCode) {
      const params = {
        visitPlanInfoId: this.id,
        terminalCode: this.clientCode,
        actDetailCode: activityCode,
      };
      request.post('/sfa/sfaWorkDailyController/getTpmActDetailReport', params).then((res) => {
        if (!res.success || !res.result) return false;
        this.activityObj = res.result || {};
        this.activityObj.pdfUrl = this.activityObj.objectName ? `/upload/downloadController/download?objectName=${this.activityObj.objectName}` : '';
      });
    },
    // 点击查看协议
    viewTreaty() {
      if (!this.activityObj.pdfUrl) {
        this.$message.warning('没有可查看的协议！');
        return false;
      }
      this.showDialog = true;
    },
    // 关闭弹窗
    closeDialog() {
      this.showDialog = false;
    },
    // 获取协议总页数
    getPageNum(val) {
      this.totalPage = val;
    },
    // 上一页
    prevPage() {
      if (this.curPage > 1) {
        this.curPage -= 1;
      }
    },
    // 下一页
    nextPage() {
      if (this.curPage < this.totalPage) {
        this.curPage += 1;
      }
    },
    // 下载
    download() {
      if (!this.activityObj.pdfUrl) {
        this.$message.warning('没有可下载的协议！');
        return false;
      }
      const a = document.createElement('a');
      const fileName = `${this.activityObj.actName}.pdf`;
      a.setAttribute('href', this.activityObj.pdfUrl);
      a.setAttribute('download', fileName);
      a.click();
    },
  },
};
</script>
<style lang="less" scoped>
/deep/ .el-tabs--left{
  overflow: initial;
}
.all-container{
  display: flex;
  flex-direction: row;
  align-items: stretch;
  .body{
    flex: 1;
  }
}
.image{
  width: 160px;
  height: 160px;
}
.mt-20{
  margin-top: 20px;
}
.mb-10{
  margin-bottom: 10px;
}
.product-container{
  padding: 10px;
  margin-bottom: 20px;
  background-color: #f6f7fb;
  .product-title{
    font-size: 16px;
  }
  .product-title-item{
    font-size: 14px;
  }
  .product-table{
    margin-bottom: 10px;
  }
}
.footer {
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 20px;
  width: 100%;
  height: 73px;
  z-index: 999;
  border-top: 1px solid #e4e4e4;
  justify-content: flex-end;
  .page {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
  .download{
    position: absolute;
    right: 50px;
    .el-button {
      margin-left: 8px;
    }
  }
}
</style>
