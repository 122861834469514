import ProjectActivity from '../../../../activity/project/form';
import StableChargeActivity from '../../../../activity/quota/form';
import PromotionActivity from '../../../../activity/promotion/form';
import RebateActivity from '../../../../activity/rebate/form';

const mixin = {
  components: {
    ProjectActivity,
    StableChargeActivity,
    PromotionActivity,
    RebateActivity,
  },
};

export default mixin;
