var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "vxe-table",
        { attrs: { data: _vm.competObj.sfaVisitStepColletItemReqVos } },
        [
          _c("vxe-table-column", {
            attrs: { title: "序号" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var rowIndex = ref.rowIndex
                  return [_vm._v(_vm._s(rowIndex + 1))]
                },
              },
            ]),
          }),
          _c("vxe-table-column", {
            attrs: { title: "所属品牌", field: "brand" },
          }),
          _c("vxe-table-column", {
            attrs: { title: "所属系列", field: "series" },
          }),
          _c("vxe-table-column", { attrs: { title: "SKU", field: "sku" } }),
          _c("vxe-table-column", {
            attrs: { title: "铺货数量", field: "quantity" },
          }),
        ],
        1
      ),
      _c("div", [
        _vm._v("活动信息：" + _vm._s(_vm.competObj.activityInfo || "")),
      ]),
      _c(
        "div",
        [
          _c("span", [_vm._v("图片：")]),
          _vm.competObj.sfaVisitPictureReqVos.length > 0
            ? _c(
                "div",
                _vm._l(
                  _vm.competObj.sfaVisitPictureReqVos,
                  function (item, index) {
                    return _c("el-image", {
                      key: index,
                      staticClass: "image",
                      attrs: {
                        src: item.urlPathPrefix + item.urlPath,
                        "preview-src-list": [item.urlPathPrefix + item.urlPath],
                      },
                    })
                  }
                ),
                1
              )
            : _c("EmptyImage", { staticClass: "mb-10" }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }