var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "vxe-table",
    { attrs: { data: _vm.tableData } },
    [
      _c("vxe-table-column", {
        attrs: { title: "序号" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (ref) {
              var rowIndex = ref.rowIndex
              return [_vm._v(_vm._s(rowIndex + 1))]
            },
          },
        ]),
      }),
      _c("vxe-table-column", {
        attrs: { title: "产品层级名称", field: "productLevelName" },
      }),
      _c("vxe-table-column", {
        attrs: { title: "产品编码", field: "productCode" },
      }),
      _c("vxe-table-column", {
        attrs: { title: "产品名称", field: "productName" },
      }),
      _c("vxe-table-column", {
        attrs: { title: "单位", field: "saleUnitName" },
      }),
      _c("vxe-table-column", {
        attrs: { title: "库存数量", field: "quantity" },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }