<template>
  <vxe-table :data="tableData">
    <vxe-table-column title="序号">
      <template v-slot="{rowIndex}">{{rowIndex+1}}</template>
    </vxe-table-column>
    <vxe-table-column title="产品层级名称" field="productLevelName"></vxe-table-column>
    <vxe-table-column title="产品编码" field="productCode"></vxe-table-column>
    <vxe-table-column title="产品名称" field="productName"></vxe-table-column>
    <vxe-table-column title="单位" field="saleUnitName"></vxe-table-column>
    <vxe-table-column title="库存数量" field="quantity"></vxe-table-column>
  </vxe-table>
</template>

<script>
const isObject = (value) => Object.prototype.toString.call(value) === '[object Object]';
export default {
  enNamae: '库存盘点',
  props: {
    value: Object,
  },
  watch: {
    value: {
      handler(curVal) {
        // desc 这里本身就是数组
        if (curVal && Array.isArray(curVal.stockDetailGroups)) {
          this.tableData = [];
          curVal.stockDetailGroups.map((v) => {
            if (v.visitStepStockList && v.visitStepStockList.length > 0) {
              this.tableData = this.tableData.concat(v.visitStepStockList);
            }
            return v;
          });
        }
      },
      immediate: true,
    },
  },
  data() {
    return {
      tableData: [],
    };
  },
};
</script>
