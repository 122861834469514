<script>
import Form, { formCreate } from '../../../../../../../../components/form';
import request from '../../../../../../../../utils/request';

import DetailBasicInfo from './components/detail_basic.vue';
import DetailSummary from './components/detail_summary.vue';

formCreate.component('sfaVisitDetailDetailBasicInfo', DetailBasicInfo);
formCreate.component('sfaVisitDetailDetailSummary', DetailSummary);

export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      buttons: {
        submit: false,
      },
    };
  },
  methods: {
    getDetail() {
      // todo 1.先获取数据字典，根据数据字典来判断需要展示哪些内容
      // request.post('123')

      // todo 2.在获取详情去设置，
      request.get('/sfa/sfaVisitDealerDetailController/queryVisitDetailReportById', { id: this.formConfig.row.id }).then((res) => {
        console.log('查看详情============', res);
        if (!res.success || !res.result) return;
        this.setValue({
          fieldBasic: { ...res.result },
          fieldSummary: { ...res.result },
        });
      });
    },
  },
  async created() {
    await this.getFormRule('sfaCenter_visitManage_visitDetail_detailForm');
    // if (this.formConfig.row && this.formConfig.row.id) this.getDetail();
    if (this.formConfig.row) {
      this.setValue({
        fieldBasic: this.formConfig.row,
        fieldSummary: this.formConfig.row,
      });
    }
  },
};
</script>
