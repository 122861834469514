var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("Title", [_vm._v("常规货架陈列")]),
      [
        _vm.storeCheckObj.regularShelfDisplayList.length > 0
          ? _c(
              "div",
              _vm._l(
                _vm.storeCheckObj.regularShelfDisplayList,
                function (item, index) {
                  return _c("el-image", {
                    key: index,
                    staticClass: "image",
                    attrs: {
                      src: item.urlPathPrefix + item.urlPath,
                      "preview-src-list": [item.urlPathPrefix + item.urlPath],
                    },
                  })
                }
              ),
              1
            )
          : _c("EmptyImage", { staticClass: "mb-10" }),
      ],
      _c("Title", [_vm._v("物料陈列")]),
      [
        _vm.storeCheckObj.materialDisplayList.length > 0
          ? _c(
              "div",
              _vm._l(
                _vm.storeCheckObj.materialDisplayList,
                function (item, index) {
                  return _c("el-image", {
                    key: index,
                    staticClass: "image",
                    attrs: {
                      src: item.urlPathPrefix + item.urlPath,
                      "preview-src-list": [item.urlPathPrefix + item.urlPath],
                    },
                  })
                }
              ),
              1
            )
          : _c("EmptyImage", { staticClass: "mb-10" }),
      ],
      _c("Title", [_vm._v("价格签")]),
      [
        _vm.storeCheckObj.priceList.length > 0
          ? _c(
              "div",
              _vm._l(_vm.storeCheckObj.priceList, function (item, index) {
                return _c("el-image", {
                  key: index,
                  staticClass: "image",
                  attrs: {
                    src: item.urlPathPrefix + item.urlPath,
                    "preview-src-list": [item.urlPathPrefix + item.urlPath],
                  },
                })
              }),
              1
            )
          : _c("EmptyImage", { staticClass: "mb-10" }),
      ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }