<template>
  <div class="apply">
    <vxe-toolbar v-if="!disabled">
      <template #buttons>
        <el-link icon="el-icon-plus" @click="openActivity">新增活动</el-link>
        <el-link icon="el-icon-document" @click="openLog">流程日志</el-link>
        <el-link class="red" icon="el-icon-delete" @click="clearTable">清空</el-link>
      </template>
    </vxe-toolbar>
    <vxe-table
      border
      show-overflow
      ref="xTable"
      :data="value"
      @radio-change="checkboxChange"
    >
      <vxe-table-column
        v-for="config in columns"
        :key="config.id"
        v-bind="config"
        :min-width="config.minWidth || 100"
      >
        <template v-if="config.field === 'actCode'" #default="{ row }">
          <el-link type="primary" @click="cellClick(row)">{{ row.actCode }}</el-link>
        </template>
      </vxe-table-column>
      <vxe-table-column
        title="操作"
        fixed="right"
        width="100"
        align="center"
        :visible="columns.length > 0 && !disabled"
      >
        <template #default="{ row, rowIndex }">
          <div class="setting-btn">
            <el-popconfirm
              title="此操作将永久删除该数据？"
              confirm-button-type="text"
              @confirm="removeRow(row, rowIndex)"
            >
              <el-link class="red" slot="reference">删除</el-link>
            </el-popconfirm>
          </div>
        </template>
      </vxe-table-column>
    </vxe-table>
    <SelectConfig ref="selectConfig" @onGetSelect="onGetSelect" />
    <Modal
      :modalConfig.sync="modalConfig"
      :visible.sync="modalConfig.visible"
      style="z-index:2000"
      @onClose="closeModal"
    >
      <component :is="formName" ref="modalForm" @onClose="closeModal" :formConfig="formConfig" />
      <div class="dialog-footer">
        <el-button icon="el-icon-close" type="danger" @click="closeModal">关闭</el-button>
      </div>
    </Modal>
  </div>
</template>

<script>
import request from '@/found/utils/request';
import SelectConfig from '@/found/components/select_org_cus_ter/select_config.vue';
import Modal from '@/found/components/modal';
import { ProcessLog } from '@/found/modules';
import MarketingActivity from '@/found/views/tpm/views/activity/marketing_activity/form';
// 市场推广活动项目
import MarketingPromote from '@/found/views/tpm/views/activity/marketing_promote/form';

import mixin from './mixin';

export default {
  name: 'apply',
  props: {
    value: Array,
    disabled: Boolean,
  },
  data() {
    return {
      columns: [],
      params: {
        parentCode: 'CRM20201126000000098',
        functionCode: 'tpm_act_quota_list',
      },
      selectRow: [],
      modalConfig: {
        width: '600px',
        title: '标题',
        type: 'Full',
        visible: false,
      },
      formConfig: {},
      formName: 'ProcessLog',
    };
  },
  mixins: [mixin],
  components: {
    SelectConfig,
    Modal,
    ProcessLog,
    MarketingActivity,
    MarketingPromote,
  },
  created() {
    this.getColumns();
  },
  methods: {
    // 获取表头配置
    async getColumns() {
      const res = await request.post('/mdm/mdmtableconfig/query', this.params);
      if (res.success) {
        this.columns = res.result.column
          .filter((v) => v.visible)
          .map((v) => {
            let rowData = v;
            if (rowData.field.indexOf('checkType') !== -1) {
              rowData = {
                ...rowData,
                fixed: 'left', // 固定位置在左边
                align: 'left',
                width: '36',
                type: rowData.field.replace('checkType', ''),
                visible: !this.disabled,
              };
            }
            return rowData;
          });
      }
    },
    // 活动选择列表
    openActivity() {
      const notInCodeList = this.value ? this.value.map((v) => v.actCode) : [];
      const params = {
        functionCode: 'tpm_act_quota_list',
        data: [],
        selectionList: [],
        idKey: 'id',
        paramData: {
          notInCodeList,
        },
      };

      this.$refs.selectConfig.openSelectModal(params);
    },
    // 活动选择数据回调
    async onGetSelect(val) {
      const resetList = val.map((v) => {
        const { id, ...params } = v;
        return params;
      });
      // const newList = (this.value || []).concat(resetList);
      this.$emit('changeAct');
      this.$emit('input', resetList);
    },
    // 删除行数据
    removeRow(row, rowIndex) {
      const list = this.value.filter((v, k) => k !== rowIndex);

      this.selectRow = this.selectRow.filter((v) => v.actCode !== row.actCode);

      this.$emit('input', list);

      this.$emit('setFielValue', { field: 'detailVos', val: row });
    },
    // 清除
    clearTable() {
      if (this.value.length === 0) {
        return false;
      }
      this.$confirm('请确认是否要清空当前数据？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.$emit('input', []);
          this.selectRow = [];
          this.$emit('setFielValue');
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消',
          });
        });
    },
    // 全选
    // checkboxAll({ records }) {
    //   console.log('进入全选', records);
    //   this.selectRow = records;
    // },
    // 多选
    checkboxChange({ data }) {
      console.log('进入', data);
      this.selectRow = data;
    },
    // 流程日志
    openLog() {
      this.selectRow = this.value;
      if (this.selectRow.length !== 1) {
        this.$message.error('请选择一条数据查看');
      } else if (!this.selectRow[0].processCode) {
        this.$message.error('当前数据没有流程日志');
      } else {
        this.formConfig = {
          code: 'log',
          row: { processNo: this.selectRow[0].processCode },
        };
        this.modalConfig = {
          ...this.modalConfig,
          width: '600px',
          title: '流程日志',
          visible: true,
        };
      }
    },
    // 关闭 弹窗
    closeModal() {
      this.modalConfig = {
        ...this.modalConfig,
        width: '600px',
        title: '标题',
        visible: false,
      };

      this.formConfig = {};
      this.formName = 'ProcessLog';
    },
    // 查看详情
    cellClick(row) {
      let rows = row;
      rows = {
        ...rows,
        id: rows.actId,
        code: 'view',
      };
      if (rows.actType === 'project') {
        this.formName = 'MarketingActivity';
      } else if (rows.actType === 'promotion') {
        this.formName = 'MarketingPromote';
      }

      this.formConfig = {
        code: 'view',
        row: rows,

      };

      this.modalConfig = {
        ...this.modalConfig,
        width: '80%',
        title: '查看',
        visible: true,
      };
    },
  },
};
</script>

<style lang="less" scepod>
.red {
  color: #f56c6c !important;
}
</style>
