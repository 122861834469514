var render, staticRenderFns
import script from "./form_detail.vue?vue&type=script&lang=js&"
export * from "./form_detail.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/lib/jenkins/workspace/web-page/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1754040a')) {
      api.createRecord('1754040a', component.options)
    } else {
      api.reload('1754040a', component.options)
    }
    
  }
}
component.options.__file = "found/views/tpm/views/pay_center/write_off/components/write_off_details/visitDetail/form_detail.vue"
export default component.exports