import { render, staticRenderFns } from "./order_collection.vue?vue&type=template&id=52030bd7&"
import script from "./order_collection.vue?vue&type=script&lang=js&"
export * from "./order_collection.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/lib/jenkins/workspace/web-page/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('52030bd7')) {
      api.createRecord('52030bd7', component.options)
    } else {
      api.reload('52030bd7', component.options)
    }
    module.hot.accept("./order_collection.vue?vue&type=template&id=52030bd7&", function () {
      api.rerender('52030bd7', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "found/views/tpm/views/pay_center/write_off/components/write_off_details/visitDetail/components/order_collection.vue"
export default component.exports