<template>
  <div class="box">
    <div>
      <el-form
        :model="infoObj"
        label-position="right"
        ref="infoObjFormRef"
        label-width="100px"
      >
        <el-row>
          <el-col :span="8">
            <el-form-item
              prop="searchInput"
              label="人员名称"
            >
              <el-input v-model="searchInput"
              @input="searchWord"
              class="search_input"
              placeholder="请输入人员名称"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item
              :rules="[{ required: true, message: '开始时间必填' }]"
              prop="startTime"
              label="开始时间"
            >
              <el-date-picker
                :disabled="formConfig.code === 'view'"
                v-model="infoObj.startTime"
                :picker-options="{
                  disabledDate: (time) => {
                    if (infoObj.endTime) {
                      return (
                        time.getTime() > new Date(infoObj.endTime).getTime()
                      );
                    }
                  },
                }"
                value-format="yyyy-MM-dd"
                style="width: 100%"
                type="date"
                placeholder="选择日期"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item
              :rules="[{ required: true, message: '结束时间必填' }]"
              prop="startTime"
              label="结束时间"
            >
              <el-date-picker
                :disabled="formConfig.code === 'view'"
                v-model="infoObj.endTime"
                :picker-options="{
                  disabledDate: (time) => {
                    if (infoObj.startTime) {
                      return (
                        time.getTime() < new Date(infoObj.startTime).getTime()
                      );
                    }
                  },
                }"
                value-format="yyyy-MM-dd"
                style="width: 100%"
                type="date"
                placeholder="选择日期"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div class="transfer">
      <div class="transfer-item1">
        <vxe-table
          border
          class="transfer-item"
          ref="xTable1"
          row-id="userCode"
          show-overflow
          :data="leftData"
          @checkbox-all="selectAllEvent"
          @checkbox-change="selectChangeEvent"
        >
          <vxe-table-column type="checkbox" width="60"></vxe-table-column>
          <vxe-table-column
            field="userCode"
            title="人员编码"
          ></vxe-table-column>
          <vxe-table-column
            field="fullName"
            title="人员名称"
          ></vxe-table-column>
        </vxe-table>
        <div class="pagination">
          <el-pagination
            background
            layout="total,prev, pager, next"
            @current-change="handleCurrentChange1"
            :current-page="pagination1.current"
            :page-size="pagination1.pageSize"
            :total="pagination1.total"
          ></el-pagination>
        </div>
      </div>
      <div class="button-group">
        <el-button
          type="primary"
          class="arrow-btn"
          @click="toRight"
          icon="el-icon-arrow-right"
        ></el-button>
        <el-button
          type="primary"
          class="arrow-btn"
          @click="toLeft"
          icon="el-icon-arrow-left"
        ></el-button>
      </div>
      <div class="transfer-item1">
        <vxe-table
          border
          class="transfer-item"
          ref="xTable2"
          row-id="userCode"
          show-overflow
          show-header-overflow
          :data="rigftData1"
          :edit-config="{ trigger: 'click', mode: 'cell' }"
          @checkbox-all="selectAllEvent"
          @checkbox-change="selectChangeEvent"
        >
          <vxe-table-column type="checkbox" width="60"></vxe-table-column>
          <vxe-table-column
            field="userCode"
            title="人员编码"
          ></vxe-table-column>
          <vxe-table-column
            field="fullName"
            title="人员名称"
          ></vxe-table-column>
          <vxe-table-column
            field="tupleIndex"
            title="顺序"
            :edit-render="{
              name: '$input',
              props: { type: 'number', min: 1, max: rigftData1.length },
              events: { blur: blurSortNum },
            }"
          ></vxe-table-column>
        </vxe-table>
      </div>
    </div>
  </div>
</template>

<script>
import request from '../../../../../../../utils/request';

export default {
  data() {
    return {
      ruleForm: {
        userCode: '',
        fullName: '',
      },
      leftData: [],
      rigftData1: [],
      leftDataArray: [],
      modalConfig: {},
      formPreview: null,
      defaultSelecteRows: [],
      pagination1: {
        total: 0,
        pageSize: 10,
        current: 1,
      },
      infoObj: {
        endTime: '',
        startTime: '',
      },
      searchInput: '',
    };
  },
  props: {
    formConfig: Object,
    rigftData: Array,
  },
  watch: {
    rigftData: {
      handler(newVal, oldName) {
        if (newVal) {
          if (this.rigftData.length > 0) {
            this.infoObj.startTime = this.rigftData[0].startTime;
            this.infoObj.endTime = this.rigftData[0].endTime;
          }
          this.rigftData1 = this.rigftData;
        }
      },
      immediate: true,
    },
  },
  components: {},
  mounted() {
    this.getList();
  },
  methods: {
    // change分页
    handleCurrentChange1(val) {
      this.pagination1.current = val;
      this.getList();
    },
    searchWord(val) {
      console.log(val, this.searchInput);
      this.pagination1.current = 1;
      this.getList();
    },
    getData() {
      const params = {
        templateCode: this.formConfig.code,
      };
      request
        .get('/dms/m/saleContract/findTemplateDetailsByCode', params)
        .then((res) => {
          if (res.success) {
            this.ruleForm = {
              ...res.result,
            };
            const records = [];
            res.result.tuples.forEach((v) => {
              this.leftData.forEach((a) => {
                const rowData = a;
                if (a.userCode === v.userCode) {
                  rowData.tupleIndex = v.tupleIndex;
                  records.push(rowData);
                }
                return rowData;
              });
            });

            this.$refs.xTable1.setCheckboxRow(records, true);
            const leftData = this.$refs.xTable1.getCheckboxRecords();
            leftData.sort((a, b) => a.tupleIndex - b.tupleIndex);
            this.rigftData1 = leftData;
          }
        });
    },
    getList() {
      const params = {
        pageSize: this.pagination1.pageSize,
        pageNum: this.pagination1.current,
        enableStatus: '009',
        fullName: this.searchInput,
      };
      request
        .post('/mdm/mdmUserExtController/pageInfoList', params)
        .then((res) => {
          if (res.success) {
            this.leftData = res.result.data;
            this.pagination1.total = res.result.count;
            if (this.rigftData1.length > 0) {
              const records = [];
              this.rigftData1.forEach((v) => {
                this.leftData.forEach((a) => {
                  const rowData = a;
                  if (a.userCode === v.userCode) {
                    rowData.tupleIndex = v.tupleIndex;
                    records.push(rowData);
                  }
                  return rowData;
                });
              });
              this.$refs.xTable1.setCheckboxRow(records, true);
            }
            // if (this.formConfig.code) {
            //   this.getData();
            // }
          }
        });
    },
    selectAllEvent({ checked, records }) {
      // console.log(checked ? '所有勾选事件' : '所有取消事件', records);
    },
    selectChangeEvent({ checked, records }) {
      // console.log(checked ? '勾选事件' : '取消事件', records);
    },
    toRight() {
      if (!this.infoObj.startTime) {
        this.$message.warning('请先选择开始时间');
        return;
      }
      if (!this.infoObj.endTime) {
        this.$message.warning('请先选择结束时间');
      }
      const leftData = this.$refs.xTable1.getCheckboxRecords();
      if (!this.rigftData1.length) {
        leftData.map((v, k) => {
          const rowData = v;
          if (rowData.tupleIndex || rowData.tupleIndex === 100) {
            rowData.tupleIndex = k + 1;
          }
          rowData.startTime = this.infoObj.startTime;
          rowData.endTime = this.infoObj.endTime;
          return rowData;
        });
        leftData.sort((a, b) => a.tupleIndex - b.tupleIndex);
        this.rigftData1 = leftData;
      } else {
        const codes = this.rigftData1.map((item) => item.userCode);
        const datas = leftData.filter((item) => !codes.includes(item.userCode));
        const arr = this.rigftData1.concat(datas);
        // eslint-disable-next-line no-param-reassign
        this.rigftData1 = arr.map((item, index) => {
          const rowData = item;
          if (rowData.tupleIndex) {
            rowData.tupleIndex = index + 1;
          }
          rowData.startTime = this.infoObj.startTime;
          rowData.endTime = this.infoObj.endTime;
          return rowData;
        });
      }
      this.$emit('getResourceList', this.rigftData1);
    },
    toLeft() {
      this.$refs.xTable1.setCheckboxRow(
        this.$refs.xTable2.getCheckboxRecords(),
      );
      this.checkRightData = this.$refs.xTable2.getCheckboxRecords();
      const codes = this.checkRightData.map((item) => item.userCode);
      const datas = this.rigftData1.filter(
        (item) => !codes.includes(item.userCode),
      );
      if (datas.length) {
        datas.forEach((item, index) => {
          if (item.tupleIndex) {
            // eslint-disable-next-line no-param-reassign
            item.tupleIndex = index + 1;
          }
        });
        this.rigftData1 = datas;
      } else {
        this.rigftData1 = datas;
      }
      this.$emit('getResourceList', this.rigftData1);
    },
    isPositiveNum(s) {
      const re = /^(0|[1-9]\d*)$/;
      return re.test(s);
    },
    blurSortNum(val, v) {
      const rigftData = this.$refs.xTable2.getTableData().tableData;
      const newList = rigftData.filter((item) => val.rowid !== item.userCode);
      const obj = rigftData.find((item) => val.rowid === item.userCode);
      const objIndex = Number(obj.tupleIndex) - 1;
      let rigftDatas = [];
      if (!v.value) {
        this.$message.warning('排序值不能为空');
        rigftDatas = rigftData.map((item, index) => {
          // eslint-disable-next-line no-param-reassign
          const objs = {
            ...item,
            tupleIndex:
              val.rowid === item.userCode ? val.rowIndex + 1 : item.tupleIndex,
          };
          return objs;
        });
        this.rigftData1 = rigftDatas;
      } else {
        if (newList[objIndex]) {
          newList.splice(objIndex, 0, obj);
        } else {
          newList.push(obj);
        }
        rigftDatas = newList.map((item, index) => {
          // eslint-disable-next-line no-param-reassign
          const objs = {
            ...item,
            tupleIndex: index + 1,
          };
          return objs;
        });
        this.rigftData1 = rigftDatas;
      }
      this.$emit('getResourceList', this.rigftData1);
    },
  },
};
</script>
<style lang="less" scoped>
.box {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.transfer {
  display: flex;
  align-items: center;
  flex: 1;
}
.transfer-item1 {
  //   flex: 1;
  flex-basis: 40%;
  height: 100%;
}
.transfer-item {
  flex: 1;
  height: 100%;
}
.button-group {
  display: flex;
  flex-direction: column;
  margin: 0 20px;
  // justify-content: space-around;
}
.arrow-btn {
  margin: 20px 0;
  margin-left: 0 !important;
}
.pagination {
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
}
/deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
  color: white !important;
}
</style>
