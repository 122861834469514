<template>
  <div>
    <el-drawer
      title="查看图片"
      :visible.sync="drawer"
      direction="rtl"
      :append-to-body="true"
      size="60%"
      :before-close="handleClose">
      <div class="picture-list">
        <template v-for="(item,index) in imgList">
          <div :key="index">
            <div class="title">{{item.title}}</div>
            <div v-for="(pic,pIndex) in item.photoList" :key="pIndex" class="img-wrap">
              <el-image class="img-box"  :key="pIndex" :src="pic" :preview-src-list="item.photoList"></el-image>
            </div>
            <div v-for="(vid,vIndex) in item.videoList" :key="vIndex+'v'" class="img-wrap">
              <video class="img-box"  :key="vIndex+'v'" :src="vid" :controls="controls"></video>
            </div>
          </div>
        </template>
      </div>
    </el-drawer>
  </div>
</template>

<script>

export default {
  name: 'picture_list',
  props: {
    list: Array,

  },
  data() {
    return {
      drawer: false,
      imgList: [],
      controls: true,
    };
  },
  watch: {
    list(newVal, oldVal) {
      const photoVoList = newVal.map((v) => {
        const obj = v;
        obj.photoList = obj.photos.filter((f) => f.indexOf('mp4') === -1);
        obj.videoList = obj.photos.filter((f) => f.indexOf('mp4') !== -1);
        return obj;
      });
      this.imgList = photoVoList;
    },
  },
  components: {},
  created() {

  },
  methods: {
    open() {
      // 打开弹窗
      this.drawer = true;
    },
    // 关闭弹窗
    handleClose() {
      this.drawer = false;
    },
  },
};
</script>

<style lang="less" scepod>
.picture-list{
  padding: 20px;
}
.title{
  font-size:14px;
  font-weight: 600;
  margin: 8px 0;
}
.img-wrap{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.img-box{
  width:200px;
  height: 120px;
  margin:8px;
}
</style>
