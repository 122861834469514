<template>
<div>
  <el-form label-width="100px">
    <el-row>
      <el-col :span="12">
        <el-form-item label="进店时间">
          <TextEllipsis :text="inStoreObj.storeDateTime"></TextEllipsis>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="进店地址">
          <TextEllipsis :text="inStoreObj.storeAddress"></TextEllipsis>
        </el-form-item>
      </el-col>
      <el-col :span="24">
        <el-form-item label="进店打卡图片">
          <div v-if="inStoreObj.storePics.length>0">
            <el-image class="image" v-for="(item,index) in inStoreObj.storePics"
                      :src="item.urlPathPrefix + item.urlPath"
                      :preview-src-list="[item.urlPathPrefix + item.urlPath]"
                      :key="index">
            </el-image>
          </div>
          <EmptyImage v-else></EmptyImage>
        </el-form-item>
      </el-col>
    </el-row>
  </el-form>
</div>
</template>

<script>
import TextEllipsis from '../../../../../../../../../components/text_ellipsis';
import EmptyImage from '../../../../../../../../../components/empty';

const isObject = (value) => Object.prototype.toString.call(value) === '[object Object]';

export default {
  enName: '进店打卡',
  components: { TextEllipsis, EmptyImage },
  props: {
    value: Object,
  },
  watch: {
    value: {
      handler(curVal) {
        if (curVal && isObject(curVal)) {
          this.inStoreObj = { ...this.inStoreObj, ...curVal };
          // this.inStoreObj.inStorePicList = curVal.inStorePicList || [];
          // this.inStoreObj.outStorePicList = curVal.outStorePicList || [];
        }
      },
      immediate: true,
    },
  },
  data() {
    return {
      // 进店的数据
      inStoreObj: {
        storeDateTime: '', // 进店时间
        storeAddress: '', // 进店地址
        storePics: [], // 进店图片
      },
    };
  },
};
</script>
<style lang="less" scoped>
.image{
  width: 160px;
  height: 160px;
}
</style>
