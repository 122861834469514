var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._l(_vm.list, function (item, index) {
      return _c(
        "div",
        { key: index },
        [
          _c("Title", [_vm._v(_vm._s(item.modelName))]),
          _c(
            "el-form",
            { attrs: { "label-width": _vm.labelWidth ? _vm.labelWidth : "" } },
            [
              _c(
                "el-row",
                _vm._l(
                  item.sfaVisitStepFromControlRespVoList,
                  function (pItem, pIndex) {
                    return _c(
                      "el-col",
                      { key: pIndex, attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: pItem.controlName } },
                          [
                            pItem.controlType === "sfa_control_type_attachment"
                              ? [
                                  _vm.data[pItem.fieldName + "Name"] &&
                                  _vm.data[pItem.fieldName + "Name"].length > 0
                                    ? _c(
                                        "div",
                                        _vm._l(
                                          _vm.data[pItem.fieldName + "Name"],
                                          function (iItem, iIndex) {
                                            return _c("el-image", {
                                              key: iIndex,
                                              staticClass: "image",
                                              attrs: {
                                                src: iItem,
                                                "preview-src-list":
                                                  _vm.data[
                                                    pItem.fieldName + "Name"
                                                  ],
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      )
                                    : _c("EmptyImage"),
                                ]
                              : [
                                  _c("TextEllipsis", {
                                    attrs: {
                                      text: _vm.data[pItem.fieldName + "Name"]
                                        ? _vm.data[pItem.fieldName + "Name"]
                                        : _vm.data[pItem.fieldName],
                                    },
                                  }),
                                ],
                          ],
                          2
                        ),
                      ],
                      1
                    )
                  }
                ),
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }