import request from '../../../../../../utils/request';
import Form, { formCreate } from '../../../../../../components/form';
import CostBudget, { RebateDetailList } from '../../../../components';

formCreate.component('CostBudget', CostBudget);
formCreate.component('RebateDetailList', RebateDetailList);

export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      formParentCode: 'CRM20210427000001583',
      formFunctionCode: 'tpm_rebate_activity_form',
      buttonText: {
        submit: '保存',
      },
      buttons: {
        submitAudit: this.formConfig.code !== 'view',
      },
    };
  },
  methods: {
    // 设置rule
    setRule(item) {
      const v = item;
      if (v.field === 'beginDate' || v.field === 'endDate') {
        v.props = {
          ...v.props,
          type: 'datetime',
          valueFormat: 'yyyy-MM-dd HH:mm:ss',
          relation: {
            beginField: 'beginDate',
            endField: 'endDate',
          },
        };
      } else if (v.field === 'budgetSubjectCode') {
        v.props = {
          ...v.props,
          params: {
            functionCode: 'tpm_rebate_subject_list',
          },
          getValue: (field) => this.getFieldValue(field),
          state: this.formConfig.budgetState,
        };
      } else if (v.field === 'categoriesCode') {
        v.restful = '/tpm/tpmActController/getCategoriesByBudget';
        v.restfulParams = {
          enableStatus: '009',
          actType: 'department_charge',
        };
        v.restfulParamsGetValue = { controlIds: 'controlIds' };
        v.optionsKey = { label: 'categoriesName', value: 'categoriesCode' };
        v.refresh = true;
      } else if (v.field === 'detailVos') { // 自定义组件
        v.props = {
          ...v.props,
          functionCode: 'tpm_rebate_detail_list',
          getValue: (field) => this.getFieldValue(field),
        };
        v.value = []; // 组件的数据源
      }
      return v;
    },

    // 表单渲染完成后的回调
    formComplete() {
      const budgetSubjectCode = this.getRule('budgetSubjectCode');
      budgetSubjectCode.on.change = (e) => {
        let budgetControlVos = [];
        if (e) {
          budgetControlVos = e.map((v) => ({
            ...v,
            feeBudgetVos: v.feeBudgetVos.map((sub) => ({
              ...sub,
              reduceOrder: 1,
            })),
          }));
        }
        this.setValue({
          controlIds: e ? e.map((v) => v.id) : [],
          budgetSubjectCode: e ? e[0].feeBudgetCodes : '',
          budgetSubjectName: e ? e[0].budgetSubjectsName : '',
          categoriesCode: '',
          availableBalance: e ? e[0].canUseAmount : undefined,
          budgetControlVos,
        });
        this.disabled(!e, 'categoriesCode');
      };
      const beginDate = this.getRule('beginDate');
      const endDate = this.getRule('endDate');
      beginDate.on.change = this.changFn;
      endDate.on.change = this.changFn;

      const categoriesCode = this.getRule('categoriesCode');
      categoriesCode.on.change = (e) => {
        this.setValue({
          detailVos: [],
        });
      };

      if (this.formConfig.code === 'edit' || this.formConfig.code === 'view') {
        // request.get('/tpm/tpmBudgetSubjectsController/query', {
        //   id: this.formConfig.row.id,
        //   directSystemId: this.formConfig.row.directSystemId,
        //   directSystemName: this.formConfig.row.directSystemName,
        // }).then((res) => {
        //   if (res.success) {
        //     this.setValue(res.result);
        //   }
        // });
      }
    },
    // 修改日期重置编辑信息
    changFn(e) {
      if (this.formConfig.budgetState === 'current') {
        this.setValue({
          controlIds: [],
          budgetSubjectCode: '',
          budgetSubjectName: '',
          availableBalance: undefined,
          categoriesCode: '',
          detailVos: [],
          budgetControlVos: [],
        });
      }
    },

    // 表单提交
    submit(e) {
      const formData = this.getFormData();
      if (formData) {
        console.log(formData);
        // if (formData.detailVos.length !== 0) {
        const url = '/tpm/tpmActController/save';
        formData.actType = 'rebate';
        formData.saveType = 1;

        formData.detailVos = formData.detailVos.map((v) => ({
          ...v,
          categoriesCode: formData.categoriesCode,
        }));
        //   console.log(formData);

        //   if (e === 2) {
        //     this.$emit('submit', {
        //       row: formData,
        //       submitUrl: url,
        //     });
        //   } else {
        request.post(url, formData).then((res) => {
          console.log(res);
        //       // if (res.success) {
        //       //   this.$message.success('操作成功');
        //       //   this.$emit('onClose');
        //       //   this.$emit('onGetList');
        //       // }
        });
        //   }
        // } else {
        //   this.$message.error('请至少添加一条明细信息');
        // }
      }
    },
  },
};
