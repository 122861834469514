<template>
  <div>
    <Title>基础信息</Title>
    <el-form>
      <el-row>
        <el-col :span="8">
          <el-form-item label="订单号">
            <TextEllipsis :text="orderObj.orderCode"></TextEllipsis>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="上级客户">
            <TextEllipsis :text="orderObj.customerName"></TextEllipsis>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="下单日期">
            <TextEllipsis :text="orderObj.orderTime"></TextEllipsis>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="期望到货日期">
            <TextEllipsis :text="orderObj.expectReceive"></TextEllipsis>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="联系人">
            <TextEllipsis :text="orderObj.contacts"></TextEllipsis>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="联系电话">
            <TextEllipsis :text="orderObj.telephone"></TextEllipsis>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="收货地址">
            <TextEllipsis :text="orderObj.address"></TextEllipsis>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="备注">
            <TextEllipsis :text="orderObj.remarks"></TextEllipsis>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <Title>订单信息</Title>
    <vxe-table :data="orderObj.sfaVisitStepOrderItems">
      <vxe-table-column title="序号">
        <template v-slot="{rowIndex}">{{rowIndex+1}}</template>
      </vxe-table-column>
      <vxe-table-column title="产品系列编码" field="seriesCode"></vxe-table-column>
      <vxe-table-column title="产品系列名称" field="seriesName"></vxe-table-column>
      <vxe-table-column title="产品编码" field="productCode"></vxe-table-column>
      <vxe-table-column title="产品名称" field="productName"></vxe-table-column>
      <vxe-table-column title="单位" field="unit"></vxe-table-column>
      <vxe-table-column title="数量" field="quantity"></vxe-table-column>
    </vxe-table>
  </div>
</template>

<script>
import TextEllipsis from '../../../../../../../../../components/text_ellipsis';
import Title from '../../../../../../../../../components/title';

const isObject = (value) => Object.prototype.toString.call(value) === '[object Object]';
export default {
  enName: '订单采集',
  components: { TextEllipsis, Title },
  props: {
    value: Object,
  },
  watch: {
    value: {
      handler(curVal) {
        if (curVal && isObject(curVal)) {
          this.orderObj = {
            ...this.orderObj,
            ...curVal,
            sfaVisitStepOrderItemRespVos: curVal.sfaVisitStepOrderItemRespVos || [],
          };
        }
      },
      immediate: true,
    },
  },
  data() {
    return {
      orderObj: {
        customerName: '', // 上级客户
        expectReceive: '', // 期望
        orderTime: '', // 订单时间
        address: '', // 收货地址
        sfaVisitStepOrderItemRespVos: [], // 商品详情
        telephone: '', // 电话号码
        contacts: '', // 联系人
        orderCode: '', // 订单编号
        remarks: '', // 备注
      },
    };
  },
};
</script>
