var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "all-container" },
    [
      _vm.tabList && _vm.tabList.length > 0
        ? _c(
            "el-tabs",
            {
              staticClass: "left",
              attrs: { "tab-position": "left" },
              on: { "tab-click": _vm.tabClick },
              model: {
                value: _vm.activeTab,
                callback: function ($$v) {
                  _vm.activeTab = $$v
                },
                expression: "activeTab",
              },
            },
            _vm._l(_vm.tabList, function (item, index) {
              return _c("el-tab-pane", {
                key: index,
                attrs: { label: item.activityName, name: item.id },
              })
            }),
            1
          )
        : _vm._e(),
      _vm.displayObj && _vm.displayObj.activityCode
        ? _c(
            "div",
            { staticClass: "body" },
            [
              _c("Title", [_vm._v("基本信息")]),
              _c(
                "el-form",
                { attrs: { "label-width": "100px" } },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "活动编码" } },
                            [
                              _c("TextEllipsis", {
                                attrs: { text: _vm.displayObj.activityCode },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "活动名称" } },
                            [
                              _c("TextEllipsis", {
                                attrs: { text: _vm.displayObj.activityName },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "活动类型" } },
                            [
                              _c("TextEllipsis", {
                                attrs: { text: "陈列活动" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "活动执行时间" } },
                            [
                              _vm.displayObj.activityStartTime &&
                              _vm.displayObj.activityEndTime
                                ? [
                                    _c("TextEllipsis", {
                                      attrs: {
                                        text:
                                          _vm.displayObj.activityStartTime +
                                          " 至 " +
                                          _vm.displayObj.activityEndTime,
                                      },
                                    }),
                                  ]
                                : _vm._e(),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("Title", [_vm._v("活动信息")]),
              _c(
                "el-form",
                { attrs: { "label-width": "100px" } },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "活动执行频率" } },
                            [
                              _c("TextEllipsis", {
                                attrs: {
                                  text: _vm.displayObj.activityFrequency,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "活动描述" } },
                            [
                              _c("TextEllipsis", {
                                attrs: { text: _vm.displayObj.activityDesc },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "活动要求" } },
                            [
                              _c("TextEllipsis", {
                                attrs: {
                                  text: _vm.displayObj.activityRequireDesc,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "示例图片" } },
                            [
                              _vm.displayObj.displayContent &&
                              _vm.displayObj.displayContent.pictureList &&
                              _vm.displayObj.displayContent.pictureList.length >
                                0
                                ? _c(
                                    "div",
                                    _vm._l(
                                      _vm.displayObj.displayContent.pictureList,
                                      function (item, index) {
                                        return _c("el-image", {
                                          key: index,
                                          staticClass: "image",
                                          attrs: {
                                            src:
                                              item.urlPathPrefix + item.urlPath,
                                            "preview-src-list": [
                                              item.urlPathPrefix + item.urlPath,
                                            ],
                                          },
                                        })
                                      }
                                    ),
                                    1
                                  )
                                : _c("EmptyImage", { staticClass: "mb-10" }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "陈列要求" } },
                            [
                              _c(
                                "vxe-table",
                                {
                                  attrs: {
                                    data:
                                      _vm.displayObj.displayContent &&
                                      _vm.displayObj.displayContent
                                        .activityProductList
                                        ? _vm.displayObj.displayContent
                                            .activityProductList
                                        : [],
                                  },
                                },
                                [
                                  _c("vxe-table-column", {
                                    attrs: { title: "序号" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (ref) {
                                            var rowIndex = ref.rowIndex
                                            return [
                                              _vm._v(_vm._s(rowIndex + 1)),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      856207403
                                    ),
                                  }),
                                  _c("vxe-table-column", {
                                    attrs: {
                                      title: "系列编码",
                                      field: "seriesCode",
                                    },
                                  }),
                                  _c("vxe-table-column", {
                                    attrs: {
                                      title: "系列名称",
                                      field: "seriesName",
                                    },
                                  }),
                                  _c("vxe-table-column", {
                                    attrs: {
                                      title: "产品编码",
                                      field: "productCode",
                                    },
                                  }),
                                  _c("vxe-table-column", {
                                    attrs: {
                                      title: "产品名称",
                                      field: "productName",
                                    },
                                  }),
                                  _c("vxe-table-column", {
                                    attrs: {
                                      title: "最低陈列数",
                                      field: "displayNum",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("Title", [_vm._v("执行结果")]),
              _vm.displayObj.activityRequireReqVoList &&
              _vm.displayObj.activityRequireReqVoList.length > 0
                ? _c(
                    "div",
                    [
                      _c(
                        "el-form",
                        { attrs: { "label-width": "100px" } },
                        _vm._l(
                          _vm.displayObj.activityRequireReqVoList,
                          function (item, index) {
                            return _c(
                              "el-form-item",
                              {
                                key: index,
                                attrs: { label: item.activityRequireName },
                              },
                              [
                                item.pictureList && item.pictureList.length > 0
                                  ? _c(
                                      "div",
                                      _vm._l(
                                        item.pictureList,
                                        function (pItem, pIndex) {
                                          return _c("el-image", {
                                            key: pIndex,
                                            staticClass: "image",
                                            attrs: {
                                              src:
                                                pItem.urlPathPrefix +
                                                pItem.urlPath,
                                              "preview-src-list": [
                                                pItem.urlPathPrefix +
                                                  pItem.urlPath,
                                              ],
                                            },
                                          })
                                        }
                                      ),
                                      1
                                    )
                                  : _c("EmptyImage", { staticClass: "mb-10" }),
                              ],
                              1
                            )
                          }
                        ),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "div",
                { staticClass: "mt-20" },
                [_c("ExtendForm", { attrs: { value: _vm.displayObj } })],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }