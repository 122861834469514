var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        { attrs: { "label-width": "150px" } },
        [
          _c(
            "el-row",
            [
              _vm._l(_vm.visitDetailStep, function (item, index) {
                return _c(
                  "el-col",
                  { key: index, attrs: { span: 6 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: item.formName } },
                      [_c("TextEllipsis", { attrs: { text: item.formValue } })],
                      1
                    ),
                  ],
                  1
                )
              }),
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "拜访小结" } },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "textarea",
                          autosize: { minRows: 4 },
                          readonly: "",
                        },
                        model: {
                          value: _vm.visitSummary,
                          callback: function ($$v) {
                            _vm.visitSummary = $$v
                          },
                          expression: "visitSummary",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "el-tabs",
        {
          attrs: { type: "card" },
          on: { "tab-click": _vm.tabClick },
          model: {
            value: _vm.activeTab,
            callback: function ($$v) {
              _vm.activeTab = $$v
            },
            expression: "activeTab",
          },
        },
        _vm._l(_vm.componentArr, function (item, index) {
          return _c(
            "el-tab-pane",
            { key: index, attrs: { label: item.tabName, name: item.tabIndex } },
            [
              item.isSuccess === "1"
                ? [
                    _c(item.componentName, {
                      tag: "component",
                      attrs: { value: item.propsValue },
                    }),
                    _c(
                      "div",
                      { staticClass: "mt-20" },
                      [_c("ExtendForm", { attrs: { value: item.propsValue } })],
                      1
                    ),
                  ]
                : [
                    _c("div", { staticClass: "mt-20" }, [
                      _vm._v("该拜访步骤暂未完成！"),
                    ]),
                  ],
            ],
            2
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }