<template>
  <div>
    <div class="material">
      <el-row type="flex">
        <el-col class="table-th" :span="4">活动明细名称</el-col>
        <el-col :span="20">
          <el-row type="flex">
            <el-col class="table-th" :span="4">要求核销资料</el-col>
            <el-col class="table-th" :span="7">描述</el-col>
            <el-col class="table-th" :span="6">示例</el-col>
            <el-col class="table-th" :span="7">上传</el-col>
          </el-row>
        </el-col>
      </el-row>
      <template v-for="(item, k) in list">
        <el-row :key="k" type="flex" v-if="item.auditRequires.length > 0">
          <el-col class="table-td" :span="4">{{ item.fineName }}</el-col>
          <el-col :span="20">
            <el-row
              v-for="(subItem, j) in item.auditRequires"
              :key="j"
              type="flex"
            >
              <el-col class="table-td" :span="4">{{
                subItem.exampleName
              }}</el-col>
              <el-col class="table-td" :span="7">{{ subItem.remarks }}</el-col>
              <el-col class="table-td" :span="6">
                <div>
                  <el-image
                    style="max-width: 80px"
                    :src="getImgUrls(subItem.pictureRespVos)[0]"
                    :preview-src-list="getImgUrls(subItem.pictureRespVos)"
                  ></el-image>
                </div>
              </el-col>
              <el-col class="table-td table-td1" :span="7">
                <Upload
                  style="width: 100%"
                  :disabled="formConfig.disabled"
                  :astrictState="astrictState"
                  fileMsg
                  clearable
                  v-model="subItem.auditFileReqVos"
                  @getFileList="(v) => getFileList(v, k, j)"
                  >上传</Upload
                >
                <el-button
                  v-if="subItem.exampleCode === 'HX00019'&&!formConfig.disabled"
                  @click="personChange(item, k,j)"
                  size="small"
                  type="primary"
                  >选择人员</el-button
                >
                <div v-if="subItem.exampleCode === 'HX00019'">
                  <div
                    v-for="(item, index) in chooseData"
                    :key="index"
                    class="file-list"
                  >
                    <i class="el-icon-tickets"></i>
                    <div class="file-name">{{ item.fullName }}</div>
                    <i class="el-icon-view" @click="handleView(item)"></i>
                    <i class="el-icon-close" v-if="!formConfig.disabled" @click="handleRemove(item,k,j)"></i>
                  </div>
                </div>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
      </template>
    </div>
    <Modal
      :modalConfig.sync="modalConfig"
      :visible.sync="modalConfig.visible"
      @onClose="closeModal"
    >
      <component
        ref="modalForm"
        :is="formName"
        :formConfig="formConfig1"
        @getResourceList="getResourceList"
        :rigftData="rigftData"
      />
      <div class="dialog-footer">
        <el-button type="danger" v-if="formName==='Person'" @click="confirmResource">确定</el-button>
        <el-button icon="el-icon-close" type="danger" @click="closeModal"
          >关闭</el-button
        >
      </div>
    </Modal>
  </div>
</template>

<script>
import request from '../../../../../../../utils/request';
import Upload from '../../../../../../../modules/upload/upload.vue';
import Modal from '../../../../../../../components/modal';
import Person from './person.vue';
import ImgView from './view_list.vue';

export default {
  name: 'material',
  props: {
    formConfig: Object,
    getFieldValue: Function,
  },
  data() {
    return {
      list: [],
      astrictState: false,
      chooseIndex: null,
      vIndex: null,
      chooseData: [],
      modalConfig: {
        width: '70%',
        title: '选择人员',
        type: 'Modal',
        visible: false,
      },
      formName: 'Person',
      formConfig1: {},
      rigftData: [],
    };
  },
  components: {
    Upload,
    Modal,
    Person,
    ImgView,
  },
  watch: {
    formConfig: {
      handler(newVal, oldName) {
        if (newVal) {
          console.log(this.formConfig);
          this.rigftData = this.formConfig.row.punchReqVos;
          this.chooseData = this.formConfig.row.punchReqVos;
        }
      },
      immediate: true,
    },
  },
  async mounted() {
    const res = await request.post(
      '/tpm/tpmAuditController/costTypeFineListByFineCode',
      {
        ...this.formConfig.row,
      },
    );
    if (res.success) {
      this.list = res.result.map((item) => {
        const v = { ...item };
        v.auditRequires = v.auditRequires
          ? v.auditRequires.map((subItem) => ({
            ...subItem,
            auditFileReqVos: subItem.auditFileReqVos
              ? subItem.auditFileReqVos.map((subChild) => ({
                ...subChild,
                url: subChild.addressUrl,
                fileName: subChild.auditRequireNames,
              }))
              : [],
          }))
          : [];
        return v;
      });
    }
  },
  methods: {
    handleRemove(item, k, j) {
      this.$confirm(`确定移除 ${item.fullName}？`).then(
        (res) => {
          if (res === 'confirm') {
            const list = this.chooseData.filter((v) => v.userCode !== item.userCode);
            this.rigftData = list;
            this.chooseData = list;
            const _list = this.chooseData.map((m) => {
              const v = m;
              return {
                fineCode: this.list[k].fineCode,
                exampleCode: this.list[k].auditRequires[j].exampleCode,
                userCode: v.userCode,
                userName: v.userName,
                fullName: v.fullName,
                userPosCode: v.positionCode,
                startTime: v.startTime,
                endTime: v.endTime,
              };
            });

            this.list[k].auditRequires[j].punchReqVos = _list;

            let means = [];
            this.list.forEach((v) => {
              v.auditRequires.forEach((sub) => {
                means = means.concat(sub.punchReqVos || []);
              });
            });

            this.$emit(
              'setPersonValue',
              means.filter((v) => v),
            );
          }
        },
        () => {},
      );
    },
    // 示例图片地址
    getImgUrls(data) {
      let list = [];

      if (data) {
        list = data.map((v) => v.urlAddress);
      }

      return list;
    },
    // 关闭弹框
    closeModal() {
      this.modalConfig.visible = false;
    },
    // 获取选中的资料
    getResourceList(val) {
      console.log(val);
      this.chooseData = val;
    },
    // 确认资料
    async confirmResource() {
      this.modalConfig.visible = false;
      // this.formConfig.row.punchReqVos = this.chooseData;
      console.log(this.chooseData);
      const _list = this.chooseData.map((item) => {
        const v = item;
        return {
          fineCode: this.list[this.chooseIndex].fineCode,
          exampleCode: this.list[this.chooseIndex].auditRequires[this.vIndex].exampleCode,
          userCode: v.userCode,
          userName: v.userName,
          fullName: v.fullName,
          userPosCode: v.positionCode,
          startTime: v.startTime,
          endTime: v.endTime,
        };
      });

      this.list[this.chooseIndex].auditRequires[this.vIndex].punchReqVos = _list;

      let means = [];
      this.list.forEach((v) => {
        v.auditRequires.forEach((sub) => {
          means = means.concat(sub.punchReqVos || []);
        });
      });

      this.$emit(
        'setPersonValue',
        means.filter((v) => v),
      );
    },
    // 打开选择人员弹窗
    personChange(item, k, j) {
      this.chooseIndex = k;
      this.vIndex = j;
      this.formConfig1 = {};
      this.modalConfig = {
        width: '70%',
        title: '选择人员',
        type: 'Modal',
        visible: true,
      };
      this.formName = 'Person';
    },
    handleView(item) {
      console.log(item);
      this.modalConfig = {
        width: '70%',
        title: '查看图片',
        type: 'Full',
        visible: true,
      };
      this.formConfig1 = {
        wsUserName: item.userName,
        startTime: item.startTime,
        endTime: item.endTime,
      };
      this.formName = 'ImgView';
    },
    // 获取资料列表
    getFileList(val, k, j) {
      const _list = val.map((item) => {
        const v = item;
        return {
          ...v,
          auditFileType: 1,
          fineCode: this.list[k].fineCode,
          addressUrl: v.url,
          auditRequireNames: v.fileName,
          exampleCode: this.list[k].auditRequires[j].exampleCode,
        };
      });

      this.list[k].auditRequires[j].auditFileReqVos = _list;

      let means = [];
      this.list.forEach((v) => {
        v.auditRequires.forEach((sub) => {
          means = means.concat(sub.auditFileReqVos || []);
        });
      });

      this.$emit(
        'setFielValue',
        means.filter((v) => v),
      );
    },
  },
};
</script>

<style lang="less" scoped>
.material {
  border-top: 1px solid #dcdfe6;
  border-left: 1px solid #dcdfe6;
  margin-bottom: 20px;
  .table-th {
    word-break: break-all;
    padding: 6px 10px;
    background-color: #f8f8f9;
    border-right: 1px solid #dcdfe6;
    border-bottom: 1px solid #dcdfe6;
    height: 36px;
    line-height: 24px;
    font-size: 12px;
    font-weight: 700;
    color: #606266;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .table-td {
    align-items: center;
    display: flex;
    border-right: 1px solid #dcdfe6;
    border-bottom: 1px solid #dcdfe6;
    min-height: 36px;
    word-break: break-all;
    padding: 6px 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .table-td1{
    flex-wrap: wrap;
  }
}
.file-list {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 18px;
  .file-name {
    flex: 1;
    padding: 0 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    &:hover {
      background: rgba(0, 0, 0, 0.1);
    }
  }
  > i {
    padding: 0 5px;
  }
}
</style>
