var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "material" },
        [
          _c(
            "el-row",
            { attrs: { type: "flex" } },
            [
              _c("el-col", { staticClass: "table-th", attrs: { span: 4 } }, [
                _vm._v("活动明细名称"),
              ]),
              _c(
                "el-col",
                { attrs: { span: 20 } },
                [
                  _c(
                    "el-row",
                    { attrs: { type: "flex" } },
                    [
                      _c(
                        "el-col",
                        { staticClass: "table-th", attrs: { span: 4 } },
                        [_vm._v("要求核销资料")]
                      ),
                      _c(
                        "el-col",
                        { staticClass: "table-th", attrs: { span: 7 } },
                        [_vm._v("描述")]
                      ),
                      _c(
                        "el-col",
                        { staticClass: "table-th", attrs: { span: 6 } },
                        [_vm._v("示例")]
                      ),
                      _c(
                        "el-col",
                        { staticClass: "table-th", attrs: { span: 7 } },
                        [_vm._v("上传")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._l(_vm.list, function (item, k) {
            return [
              item.auditRequires.length > 0
                ? _c(
                    "el-row",
                    { key: k, attrs: { type: "flex" } },
                    [
                      _c(
                        "el-col",
                        { staticClass: "table-td", attrs: { span: 4 } },
                        [_vm._v(_vm._s(item.fineName))]
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 20 } },
                        _vm._l(item.auditRequires, function (subItem, j) {
                          return _c(
                            "el-row",
                            { key: j, attrs: { type: "flex" } },
                            [
                              _c(
                                "el-col",
                                { staticClass: "table-td", attrs: { span: 4 } },
                                [_vm._v(_vm._s(subItem.exampleName))]
                              ),
                              _c(
                                "el-col",
                                { staticClass: "table-td", attrs: { span: 7 } },
                                [_vm._v(_vm._s(subItem.remarks))]
                              ),
                              _c(
                                "el-col",
                                { staticClass: "table-td", attrs: { span: 6 } },
                                [
                                  _c(
                                    "div",
                                    [
                                      _c("el-image", {
                                        staticStyle: { "max-width": "80px" },
                                        attrs: {
                                          src: _vm.getImgUrls(
                                            subItem.pictureRespVos
                                          )[0],
                                          "preview-src-list": _vm.getImgUrls(
                                            subItem.pictureRespVos
                                          ),
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              _c(
                                "el-col",
                                {
                                  staticClass: "table-td table-td1",
                                  attrs: { span: 7 },
                                },
                                [
                                  _c(
                                    "Upload",
                                    {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        disabled: _vm.formConfig.disabled,
                                        astrictState: _vm.astrictState,
                                        fileMsg: "",
                                        clearable: "",
                                      },
                                      on: {
                                        getFileList: function (v) {
                                          return _vm.getFileList(v, k, j)
                                        },
                                      },
                                      model: {
                                        value: subItem.auditFileReqVos,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            subItem,
                                            "auditFileReqVos",
                                            $$v
                                          )
                                        },
                                        expression: "subItem.auditFileReqVos",
                                      },
                                    },
                                    [_vm._v("上传")]
                                  ),
                                  subItem.exampleCode === "HX00019" &&
                                  !_vm.formConfig.disabled
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            size: "small",
                                            type: "primary",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.personChange(
                                                item,
                                                k,
                                                j
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("选择人员")]
                                      )
                                    : _vm._e(),
                                  subItem.exampleCode === "HX00019"
                                    ? _c(
                                        "div",
                                        _vm._l(
                                          _vm.chooseData,
                                          function (item, index) {
                                            return _c(
                                              "div",
                                              {
                                                key: index,
                                                staticClass: "file-list",
                                              },
                                              [
                                                _c("i", {
                                                  staticClass:
                                                    "el-icon-tickets",
                                                }),
                                                _c(
                                                  "div",
                                                  { staticClass: "file-name" },
                                                  [
                                                    _vm._v(
                                                      _vm._s(item.fullName)
                                                    ),
                                                  ]
                                                ),
                                                _c("i", {
                                                  staticClass: "el-icon-view",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.handleView(
                                                        item
                                                      )
                                                    },
                                                  },
                                                }),
                                                !_vm.formConfig.disabled
                                                  ? _c("i", {
                                                      staticClass:
                                                        "el-icon-close",
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.handleRemove(
                                                            item,
                                                            k,
                                                            j
                                                          )
                                                        },
                                                      },
                                                    })
                                                  : _vm._e(),
                                              ]
                                            )
                                          }
                                        ),
                                        0
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ]
          }),
        ],
        2
      ),
      _c(
        "Modal",
        {
          attrs: {
            modalConfig: _vm.modalConfig,
            visible: _vm.modalConfig.visible,
          },
          on: {
            "update:modalConfig": function ($event) {
              _vm.modalConfig = $event
            },
            "update:modal-config": function ($event) {
              _vm.modalConfig = $event
            },
            "update:visible": function ($event) {
              return _vm.$set(_vm.modalConfig, "visible", $event)
            },
            onClose: _vm.closeModal,
          },
        },
        [
          _c(_vm.formName, {
            ref: "modalForm",
            tag: "component",
            attrs: { formConfig: _vm.formConfig1, rigftData: _vm.rigftData },
            on: { getResourceList: _vm.getResourceList },
          }),
          _c(
            "div",
            { staticClass: "dialog-footer" },
            [
              _vm.formName === "Person"
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "danger" },
                      on: { click: _vm.confirmResource },
                    },
                    [_vm._v("确定")]
                  )
                : _vm._e(),
              _c(
                "el-button",
                {
                  attrs: { icon: "el-icon-close", type: "danger" },
                  on: { click: _vm.closeModal },
                },
                [_vm._v("关闭")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }