var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("Title", [_vm._v("基础信息")]),
      _c(
        "el-form",
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "订单号" } },
                    [
                      _c("TextEllipsis", {
                        attrs: { text: _vm.orderObj.orderCode },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "上级客户" } },
                    [
                      _c("TextEllipsis", {
                        attrs: { text: _vm.orderObj.customerName },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "下单日期" } },
                    [
                      _c("TextEllipsis", {
                        attrs: { text: _vm.orderObj.orderTime },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "期望到货日期" } },
                    [
                      _c("TextEllipsis", {
                        attrs: { text: _vm.orderObj.expectReceive },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "联系人" } },
                    [
                      _c("TextEllipsis", {
                        attrs: { text: _vm.orderObj.contacts },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "联系电话" } },
                    [
                      _c("TextEllipsis", {
                        attrs: { text: _vm.orderObj.telephone },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "收货地址" } },
                    [
                      _c("TextEllipsis", {
                        attrs: { text: _vm.orderObj.address },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "备注" } },
                    [
                      _c("TextEllipsis", {
                        attrs: { text: _vm.orderObj.remarks },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("Title", [_vm._v("订单信息")]),
      _c(
        "vxe-table",
        { attrs: { data: _vm.orderObj.sfaVisitStepOrderItems } },
        [
          _c("vxe-table-column", {
            attrs: { title: "序号" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var rowIndex = ref.rowIndex
                  return [_vm._v(_vm._s(rowIndex + 1))]
                },
              },
            ]),
          }),
          _c("vxe-table-column", {
            attrs: { title: "产品系列编码", field: "seriesCode" },
          }),
          _c("vxe-table-column", {
            attrs: { title: "产品系列名称", field: "seriesName" },
          }),
          _c("vxe-table-column", {
            attrs: { title: "产品编码", field: "productCode" },
          }),
          _c("vxe-table-column", {
            attrs: { title: "产品名称", field: "productName" },
          }),
          _c("vxe-table-column", { attrs: { title: "单位", field: "unit" } }),
          _c("vxe-table-column", {
            attrs: { title: "数量", field: "quantity" },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }