var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.imgList.length > 0
      ? _c(
          "div",
          { staticClass: "picture-list" },
          [
            _vm._l(_vm.imgList, function (item, index) {
              return [
                _c("el-image", {
                  key: index,
                  staticClass: "img-box",
                  attrs: { src: item.url, "preview-src-list": _vm.imgArr },
                }),
              ]
            }),
          ],
          2
        )
      : _c("div", { staticClass: "no_image" }, [_vm._v("暂无图片")]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }