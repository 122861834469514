var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "all-container" },
    [
      _vm.tabList && _vm.tabList.length > 0
        ? _c(
            "el-tabs",
            {
              staticClass: "left",
              attrs: { "tab-position": "left" },
              on: { "tab-click": _vm.tabClick },
              model: {
                value: _vm.activeTab,
                callback: function ($$v) {
                  _vm.activeTab = $$v
                },
                expression: "activeTab",
              },
            },
            _vm._l(_vm.tabList, function (item, index) {
              return _c("el-tab-pane", {
                key: index,
                attrs: { label: item.actName, name: item.actDetailCode },
              })
            }),
            1
          )
        : _vm._e(),
      _vm.activityObj && _vm.activityObj.actDetailCode
        ? _c(
            "div",
            { staticClass: "body" },
            [
              _c("Title", [_vm._v("基本信息")]),
              _c(
                "el-form",
                { attrs: { "label-width": "110px" } },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "活动编码" } },
                            [
                              _c("TextEllipsis", {
                                attrs: { text: _vm.activityObj.actDetailCode },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "活动名称" } },
                            [
                              _c("TextEllipsis", {
                                attrs: { text: _vm.activityObj.actName },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "支付方式" } },
                            [
                              _c("TextEllipsis", {
                                attrs: { text: _vm.activityObj.payTypeName },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "总活动金额" } },
                            [
                              _c("TextEllipsis", {
                                attrs: {
                                  text: _vm.activityObj.actAmount + "元",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "总使用金额" } },
                            [
                              _c("TextEllipsis", {
                                attrs: {
                                  text: _vm.activityObj.usedAmount + "元",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "本店已使用金额" } },
                            [
                              _c("TextEllipsis", {
                                attrs: {
                                  text:
                                    _vm.activityObj.currentUsedAmount + "元",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "活动状态" } },
                            [
                              _c("TextEllipsis", {
                                attrs: { text: _vm.activityObj.actStatusName },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "活动开始时间" } },
                            [
                              _c("TextEllipsis", {
                                attrs: { text: _vm.activityObj.beginDate },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "活动结束时间" } },
                            [
                              _c("TextEllipsis", {
                                attrs: { text: _vm.activityObj.endDate },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.activityObj.pdfUrl
                ? [
                    _c("Title", [_vm._v("协议信息")]),
                    _c(
                      "el-button",
                      {
                        staticStyle: { "margin-bottom": "20px" },
                        attrs: { type: "primary" },
                        on: { click: _vm.viewTreaty },
                      },
                      [_vm._v("查看协议")]
                    ),
                  ]
                : _vm._e(),
              _vm.activityObj.distributionOrder
                ? [
                    _c("Title", [_vm._v("分销订单数据")]),
                    _c(
                      "div",
                      { staticClass: "product-container" },
                      [
                        _c("div", { staticClass: "product-title-item" }, [
                          _vm._v("本品"),
                        ]),
                        _c(
                          "vxe-table",
                          {
                            staticClass: "product-table",
                            attrs: {
                              data: _vm.activityObj.distributionOrder
                                .bpProductList,
                            },
                          },
                          [
                            _c("vxe-table-column", {
                              attrs: { title: "序号", width: "100px" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (ref) {
                                      var rowIndex = ref.rowIndex
                                      return [_vm._v(_vm._s(rowIndex + 1))]
                                    },
                                  },
                                ],
                                null,
                                false,
                                856207403
                              ),
                            }),
                            _c("vxe-table-column", {
                              attrs: {
                                title: "产品编码",
                                field: "productCode",
                                "min-width": "100px",
                                "show-overflow": "",
                              },
                            }),
                            _c("vxe-table-column", {
                              attrs: {
                                title: "产品名称",
                                field: "productName",
                                "min-width": "100px",
                                "show-overflow": "",
                              },
                            }),
                            _c("vxe-table-column", {
                              attrs: {
                                title: "单价(元)",
                                field: "price",
                                "min-width": "100px",
                                "show-overflow": "",
                              },
                            }),
                            _c("vxe-table-column", {
                              attrs: {
                                title: "单位",
                                field: "unitName",
                                "min-width": "100px",
                                "show-overflow": "",
                              },
                            }),
                            _c("vxe-table-column", {
                              attrs: {
                                title: "数量",
                                field: "quantity",
                                "min-width": "100px",
                                "show-overflow": "",
                              },
                            }),
                          ],
                          1
                        ),
                        _c("div", { staticClass: "product-title-item" }, [
                          _vm._v("赠品"),
                        ]),
                        _c(
                          "vxe-table",
                          {
                            staticClass: "product-table",
                            attrs: {
                              data: _vm.activityObj.distributionOrder
                                .zpProductList,
                            },
                          },
                          [
                            _c("vxe-table-column", {
                              attrs: { title: "序号", width: "100px" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (ref) {
                                      var rowIndex = ref.rowIndex
                                      return [_vm._v(_vm._s(rowIndex + 1))]
                                    },
                                  },
                                ],
                                null,
                                false,
                                856207403
                              ),
                            }),
                            _c("vxe-table-column", {
                              attrs: {
                                title: "产品编码",
                                field: "productCode",
                                "min-width": "100px",
                                "show-overflow": "",
                              },
                            }),
                            _c("vxe-table-column", {
                              attrs: {
                                title: "产品名称",
                                field: "productName",
                                "min-width": "100px",
                                "show-overflow": "",
                              },
                            }),
                            _c("vxe-table-column", {
                              attrs: {
                                title: "单价(元)",
                                field: "price",
                                "min-width": "100px",
                                "show-overflow": "",
                              },
                            }),
                            _c("vxe-table-column", {
                              attrs: {
                                title: "单位",
                                field: "unitName",
                                "min-width": "100px",
                                "show-overflow": "",
                              },
                            }),
                            _c("vxe-table-column", {
                              attrs: {
                                title: "数量",
                                field: "quantity",
                                "min-width": "100px",
                                "show-overflow": "",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                : _vm._e(),
              _vm.activityObj.actCollectData &&
              _vm.activityObj.actCollectData.length > 0
                ? [
                    _c("Title", [_vm._v("活动数据采集信息")]),
                    _vm._l(
                      _vm.activityObj.actCollectData.collectFormList,
                      function (item, index) {
                        return _c(
                          "div",
                          { key: index },
                          [
                            _c("Title", [_vm._v(_vm._s(item.exampleName))]),
                            item.attachmentList &&
                            item.attachmentList.length > 0
                              ? _c(
                                  "div",
                                  _vm._l(
                                    item.attachmentList,
                                    function (pItem, pIndex) {
                                      return _c("el-image", {
                                        key: pIndex,
                                        staticClass: "image",
                                        attrs: {
                                          src:
                                            pItem.urlPathPrefix + pItem.urlPath,
                                          "preview-src-list": [
                                            pItem.urlPathPrefix + pItem.urlPath,
                                          ],
                                        },
                                      })
                                    }
                                  ),
                                  1
                                )
                              : _c("EmptyImage", { staticClass: "mb-10" }),
                          ],
                          1
                        )
                      }
                    ),
                  ]
                : _vm._e(),
            ],
            2
          )
        : _vm._e(),
      _c(
        "el-drawer",
        {
          attrs: {
            title: "查看协议",
            size: "70%",
            visible: _vm.showDialog,
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showDialog = $event
            },
          },
        },
        [
          _c("pdf", {
            attrs: { src: _vm.activityObj.pdfUrl, page: _vm.curPage },
            on: { "num-pages": _vm.getPageNum },
          }),
          _c(
            "div",
            { staticClass: "footer" },
            [
              _c(
                "el-button-group",
                { staticClass: "page" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        icon: "el-icon-arrow-left",
                        disabled: _vm.curPage === 1,
                      },
                      on: { click: _vm.prevPage },
                    },
                    [_vm._v("上一页")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        icon: "el-icon-arrow-right",
                        disabled:
                          !_vm.totalPage || _vm.curPage === _vm.totalPage,
                      },
                      on: { click: _vm.nextPage },
                    },
                    [_vm._v("下一页")]
                  ),
                ],
                1
              ),
              _c(
                "el-button-group",
                { staticClass: "download" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", icon: "el-icon-download" },
                      on: { click: _vm.download },
                    },
                    [_vm._v("下载")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "danger", icon: "el-icon-close" },
                      on: { click: _vm.closeDialog },
                    },
                    [_vm._v("关闭")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }