var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-drawer",
        {
          attrs: {
            title: "查看图片",
            visible: _vm.drawer,
            direction: "rtl",
            "append-to-body": true,
            size: "60%",
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.drawer = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "picture-list" },
            [
              _vm._l(_vm.imgList, function (item, index) {
                return [
                  _c(
                    "div",
                    { key: index },
                    [
                      _c("div", { staticClass: "title" }, [
                        _vm._v(_vm._s(item.title)),
                      ]),
                      _vm._l(item.photoList, function (pic, pIndex) {
                        return _c(
                          "div",
                          { key: pIndex, staticClass: "img-wrap" },
                          [
                            _c("el-image", {
                              key: pIndex,
                              staticClass: "img-box",
                              attrs: {
                                src: pic,
                                "preview-src-list": item.photoList,
                              },
                            }),
                          ],
                          1
                        )
                      }),
                      _vm._l(item.videoList, function (vid, vIndex) {
                        return _c(
                          "div",
                          { key: vIndex + "v", staticClass: "img-wrap" },
                          [
                            _c("video", {
                              key: vIndex + "v",
                              staticClass: "img-box",
                              attrs: { src: vid, controls: _vm.controls },
                            }),
                          ]
                        )
                      }),
                    ],
                    2
                  ),
                ]
              }),
            ],
            2
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }