<template>
  <div>
    <div class="picture-list" v-if="imgList.length > 0">
      <template v-for="(item, index) in imgList">
        <el-image
          class="img-box"
          :key="index"
          :src="item.url"
          :preview-src-list="imgArr"
        ></el-image>
      </template>
    </div>
    <div class="no_image" v-else>暂无图片</div>
  </div>
</template>

<script>
import { Loading } from 'element-ui';
import request from '../../../../../../../utils/request';

export default {
  name: 'picture_list',
  props: {
    formConfig: Object,
  },
  data() {
    return {
      imgList: [],
      imgArr: [],
    };
  },
  components: {},
  created() {
    this.getImg();
  },
  methods: {
    async getImg() {
      const loadingInstance = Loading.service({
        fullscreen: true,
        text: '加载中...',
        background: 'rgba(0, 0, 0, 0.8)',
      });
      const res = await request.post(
        '/sfa/sfaSignFormsExtController/getSignPicturesByUserName',
        {
          wsUserName: this.formConfig.wsUserName,
          startTime: this.formConfig.startTime,
          endTime: this.formConfig.endTime,
          pageSize: 300,
        },
      );
      if (res.success) {
        console.log(res);
        this.$nextTick(() => {
          loadingInstance.close();
        });
        this.imgList = res.result;
        res.result.map((v) => {
          this.imgArr.push(v.url);
          return v;
        });
      } else {
        this.$nextTick(() => {
          loadingInstance.close();
        });
      }
    },
  },
};
</script>

<style lang="less" scepod>
.picture-list {
  padding: 20px;
}
.title {
  font-size: 14px;
  font-weight: 600;
  margin: 8px 0;
}
.img-wrap {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.img-box {
  width: 200px;
  height: 120px;
  margin: 8px;
}
.no_image {
  text-align: center;
}
</style>
